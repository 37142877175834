<template>
  <div style="height: 100%">
    <swiper ref="mySwiper" :options="swiperOptions" style="height: 100%">
      <swiper-slide v-for="(item, index) in banner" :key="index"
        ><div style="height: 100%; position: relative">
          <div
            style="padding-left: 7.3%; padding-right: 7.3%"
            :style="{
              paddingTop: token ? '75%' : '21%',
              paddingBottom: token ? '0px' : '21%',
            }"
          >
            <div class="text1">{{ title }}</div>
            <div class="text2">
              {{ welcomeText[0] }}<br />
              {{ welcomeText[1] }}
            </div>
          </div>
          <div
            style="
              background-repeat: no-repeat;
              background-size: 100% 100%;
              height: 100%;
              width: 100%;
              position: absolute;
              left: 0;
              top: 0;
            "
            :style="{ 'background-image': 'url(' + bgImg[index] + ')' }"
          ></div>
        </div>
      </swiper-slide>
    </swiper>
    <div
      style="
        padding: 0px 7.3%;
        position: absolute;
        width: 100%;
        bottom: 50px;
        z-index: 1;
      "
      :style="{ bottom: token ? '0px' : '0px' }"
    >
      <div v-if="token" @click="login">
        <button class="btn" style="margin-bottom: 70px">进入阅读</button>
        <div
          style="
            height: 5px;
            background: #ffffff;
            width: 40%;
            margin: 40px auto 10px;
          "
        ></div>
      </div>
      <div v-else>
        <button
          @click="$router.push('/login')"
          class="btn"
          style="background: #032b72; color: #fff"
        >
          账号登录
        </button>
        <button
          @click="$router.push('/register')"
          class="btn"
          style="background: #309df4; color: #fff"
        >
          新用户注册
        </button>
        <button @click="$router.push('/before')" class="btn">学前阅读</button>
        <a
          @click="$router.push('/weChat')"
          style="display: block; text-align: center"
        >
          <img src="../../assets/wx.png" />
        </a>
        <div
          style="
            height: 5px;
            background: #ffffff;
            width: 40%;
            margin: 40px auto 10px;
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { mapState } from "vuex";
import "swiper/css/swiper.css";
export default {
  data() {
    return {
      swiperOptions: { autoplay: false },
      banner: 0,
      welcomeText: [],
      bgImg: [],
      title: "",
      pid: getLocalPid(),
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      title: (state) => state.title,
    }),
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  methods: {
    login() {
      this.$router.push({name: "my",query: {pid: getLocalPid()}});
    },
  },
  mounted() {
    this.$http.get("/partner/get/sitename?pid=" + this.pid).then((res) => {
      this.banner =
        res.data.data.bgImg.length || res.data.data.welcomeText.length;
      this.bgImg = res.data.data.bgImg;
      this.welcomeText = res.data.data.welcomeText;
      this.title = res.data.data.siteName;
      this.$store.commit("getTitle", res.data.data.siteName);
      window.localStorage.setItem("title", res.data.data.siteName);
      document.title = res.data.data.siteName;
    });
  },
};
</script>
<style scoped>
.text1 {
  width: 274px;
  font-family: PingFangSC-Light;
  font-size: 29px;
  color: #ffffff;
  font-weight: 200;
  position: relative;
  margin-bottom: 25px;
  z-index: 1;
  position: relative;
}
.text1::after {
  content: "";
  position: absolute;
  width: 35px;
  height: 4px;
  background: #ffffff;
  left: 0;
  bottom: -10px;
}
.text2 {
  width: 273px;
  font-family: PingFangSC-Medium;
  font-size: 34px;
  color: #ffffff;
  line-height: 45px;
  font-weight: 500;
  z-index: 1;
  position: relative;
}
.btn {
  width: 250px;
  height: 50px;
  background: #ffffff;
  border-radius: 100px;
  border: 0 none;
  box-shadow: none;
  display: block;
  margin: 0 auto 15px;
}
</style>