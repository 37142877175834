<template>
  <div>
    <div class="text-center font-bold" style="padding-top: 100px;" v-if="!questions.length">
      <span>{{waiting ? '题目获取中...' : '暂时没有题目哦!'}}</span>
      <button v-if="!waiting && !questions.length" style="margin-left: 10px; padding: 0 10px 0 10px;" @click="getProblems">重试</button>
    </div>

    <div v-if="questions.length" style="margin: 0 10px 10px 10px;padding-top: 10px;">
      <div style="padding-left: 10px;">
				<span style="color: #333;margin-left: 4px;" class="font-bold" @click="chooseSentence(-1)"
              :class="activeSentenceIndex == -1 ? 'active': '' " v-html="title">
				</span>
      </div>
    </div>

    <div v-for="(item,index) in questions">
      <div style="margin: 20px 5px 10px 5px;padding: 10px;" class="bg-white">
        <!-- 题目 -->
        <div style="padding: 20px;font-size: 10px;background-color: #f7f7f7;border: 1px solid #ddd;line-height: 24px;"
             class="font-bold" v-html="item.title">
        </div>
        <problem-item :aProblem="[item.selectA,item.selectB,item.selectC,item.selectD]"
                      :tag = "item.id + ''" :index ="index" @getAns="getAns">
        </problem-item>

        <!-- 答案与解释 -->
        <div style="padding-top: 10px;padding-left: 10px;" v-if="isSubmit">
          <span style="line-height: 24px;color: #333;font-weight: bold;">
            您的答案： {{answerList[index].answer}}
            <br>
            正确答案：
          </span>
          <span  style="line-height: 24px;color: #333;font-weight: bold;"
                 :style="questions[index]['select'+answerList[index].answer] == questions[index].answer ? '' : 'color:red;' ">
            {{getCorrectAnswerTag(index)}}
          </span>
          <br/>
          <span style="line-height: 24px;color: #333;font-weight: bold;">知识点：</span>
          <div style="line-height: 24px;font-weight: bold;margin-left:20px;" v-html="questions[index].explanation"></div>
        </div>
        <!-- 答案与解释 -->

      </div>
    </div>

    <div  class="font-bold flex align-center justify-center" style="margin-top: 20px;">
      <div style="margin-bottom: 50px;padding: 8px 14px;border: 1px solid #d3d3d3;border-radius: 4px;" class="bg-white hover"
           @click="submitOrNextArticle" :disabled="disabled" v-if="questions.length">
        {{isSubmit ? '下一篇' : '提交'}}
      </div>
      <div style="margin-bottom: 50px;padding: 8px 14px;border: 1px solid #d3d3d3;border-radius: 4px;" class="bg-white hover"  @click="nextArticle" :disabled="disabled" v-if="!questions.length">
        下一篇
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import problemItem from '../practice/problemItem.vue'
export default{
  props: {
    articleId: {
      type: [Number, String],
      default: 0,
    },
    readLogId: {
      type: [Number, String],
      default: 0,
    },
    timestamp: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      // 选中的句子
      isCompleted: false,
      activeSentenceIndex:-2,
      questions:[],
      answerList:[],
      waiting:true,
      disabled:false,
      isSubmit:false,
      title:'请完成以下练习：'
    }
  },
  methods:{
    getCorrectAnswerTag(questIdx) {
      let q = this.questions[questIdx];
      let a = q.answer;
      if(a == q.selectA) return 'A';
      if(a == q.selectB) return 'B';
      if(a == q.selectC) return 'C';
      if(a == q.selectD) return 'D';
      return '';
    },
    // 选中句子
    chooseSentence(index)
    {
      if(this.activeSentenceIndex == index) return this.activeSentenceIndex = -2
      this.activeSentenceIndex = index
    },
    // 获取题目列表
    getProblems() {
      let obj = {
        ...this.safeMes,
        articleId: this.articleId,
        readLogId: this.readLogId,
        timestamp: this.timestamp
      }
      this.$http.post('/article/question/list',encodeURIComponent(JSON.stringify(obj)))
          .then(res => {
            let data = res.data
            if(data.code) return this.$store.commit('judgeCode',{code:data.code,msg:data.msg})
            console.log(data)
            this.questions = data.data.questions
            this.waiting = false
            while(this.questions.length != this.answerList.length)
            {
              this.answerList.push('')
            }
          })
          .catch(err => {
            this.waiting = false
            this.$message.error('获取题目失败')
          })
    },
    // 得到答案数组
    getAns(data) {
      let item = {
        answer:data.ans,
        questionId:this.questions[data.index].id
      }
      this.answerList.splice(data.index,1,item);

      //首次选择答案时，要关闭阅读日志
      if(!this.isCompleted) {
        this.completeReading();
      }
    },

    completeReading() {
      this.isCompleted = true;
      let obj = {
        ...this.safeMes,
        articleId: this.articleId,
        readLogId: this.readLogId,
        timestamp: this.timestamp
      }
      this.$http.post('/article/complete',encodeURIComponent(JSON.stringify(obj)))
          .then(res => {
          })
          .catch(err => {
            this.isCompleted = false;
          })
    },

    // 返回或提交答案
    submitOrNextArticle() {
      console.log('submitOrNextArticle');
      this.disabled = true
      if(this.isSubmit) {
        this.nextArticle();
      }
      else {
        this.submitAnswers();
      }
    },
    nextArticle() {
      console.log('nextArticle');
      this.disabled = true;
//      this.$store.commit('changeArticle',true);
      let obj = {
        ...this.safeMes,
        articleId: this.articleId,
        readLogId: this.readLogId,
        timestamp: this.timestamp,
        reason: 'next',
        pid: getLocalPid(),
      };
      this.getting = true;
      this.$http
          .post("/article/next", encodeURIComponent(JSON.stringify(obj)))
          .then((res) => {
            let data = res.data;
            if (data.code) {
              return this.$store.commit("judgeCode", {
                code: data.code,
                msg: data.msg,
              });
            }
            let ret = data.data;
            let href = window.location.href;
            window.location.href =
                href.substring(0, href.lastIndexOf("/")) +
                "/article?id=" + ret.articleId +
                "&pid=" + getLocalPid();
          })
          .catch(() => {
            this.getting = false;
            return this.$message.error("获取文章失败");
          });
    },
    submitAnswers() {
      this.disabled = true
      let index = this.answerList.findIndex(v=>v === '')
      if(index != -1) return this.$notify({
        title: '提示',
        message: '请填写所有题目哦',
        type: 'warning',
        duration:2000,
        position: 'top-left'
      });
      let obj = {
        ...this.safeMes,
        articleId:this.articleId,
        readLogId:this.readLogId,
        answers:this.answerList

      }
      console.log('submitAnswers', obj);
      this.$http.post('/article/question/submit',encodeURIComponent(JSON.stringify(obj)))
          .then(res=>{
            let data = res.data
            if(data.code)  return this.$store.commit('judgeCode',{code:data.code,msg:data.msg})
            this.$message.success('提交成功')
            this.disabled = false
            this.isSubmit = true

          })
          .catch(err=>{
            this.disabled = false
            console.log(err)
            this.$message.error('提交答案失败')
          })
    },
  },
  components:{
    problemItem
  },
  mounted() {
//    if(!this.safeMes.sign) this.$store.commit('saveSafeMes')
//    this.title = this.$route.params.obj.title
    this.getProblems()
  },
  computed:{
    ...mapState({
      safeMes:state=>state.safeMes
    })
  }
}
</script>

<style scoped>
/* 句子高亮 */
.active{
  color: white;
  background-color: #318efd;
}

.hover:hover{
  color: white;
  background-color: #318efd;
}
</style>
