var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white",staticStyle:{"height":"100%"}},[_c('Header',{attrs:{"title":'阅读计划'}}),_c('UserInfo'),(
      _vm.articleData.specialArticles && _vm.articleData.specialArticles.length > 0
    )?_c('div',{staticStyle:{"padding":"0 20px","margin-bottom":"20px"}},[_c('div',{staticStyle:{"overflow":"hidden","background":"#ffffff","border":"1px solid #309df4","box-shadow":"0px 1px 31px 0px rgba(0, 0, 0, 0.07)","border-radius":"12px","display":"flex"}},[_c('div',{staticStyle:{"flex":"0 0 35%","display":"flex","align-items":"center","justify-content":"center","background-image":"linear-gradient(180deg, #00c6f9 2%, #0077ed 100%)"}},[_c('img',{staticStyle:{"width":"75%"},attrs:{"src":_vm.articleData.specialIcon}})]),_c('div',{staticStyle:{"padding":"20px","flex":"0 0 65%"}},_vm._l((_vm.articleData.specialArticles),function(item,index){return _c('div',{key:index,style:({
            marginBottom:
              index < _vm.articleData.specialArticles.length - 1 ? '15px' : '0',
          }),on:{"click":function($event){return _vm.navTo(item.articleId)}}},[(index>0)?_c('hr'):_vm._e(),_c('div',[_c('p',{staticStyle:{"font-size":"16px","color":"#4a4a4a","margin-bottom":"7px","margin-top":"7px"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('span',{staticStyle:{"display":"flex","align-items":"center","font-size":"14px","color":"#4a4a4a"}},[_vm._v("难度： "),_c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._l((item.levelStar),function(i,k){return _c('img',{key:k + _vm.randomString(),staticStyle:{"width":"16px"},attrs:{"src":require("../../../assets/star0.png")}})}),_vm._l((5 - item.levelStar),function(i,k){return _c('img',{key:k + _vm.randomString(),staticStyle:{"width":"16px"},attrs:{"src":require("../../../assets/star1.png")}})})],2)])])])}),0)])]):_vm._e(),(
      _vm.articleData.recommendArticles &&
      _vm.articleData.recommendArticles.length > 0
    )?_c('div',{staticStyle:{"padding":"0 20px"}},[_c('div',{staticStyle:{"overflow":"hidden","background":"#ffffff","border":"1px solid #309df4","box-shadow":"0px 1px 31px 0px rgba(0, 0, 0, 0.07)","border-radius":"12px","display":"flex"}},[_c('div',{staticStyle:{"flex":"0 0 35%","display":"flex","align-items":"center","justify-content":"center","background-image":"linear-gradient(180deg, #00c6f9 2%, #0077ed 100%)"}},[_c('img',{staticStyle:{"width":"75%"},attrs:{"src":_vm.articleData.recommendIcon}})]),_c('div',{staticStyle:{"padding":"20px","flex":"0 0 65%"}},_vm._l((_vm.articleData.recommendArticles),function(item,index){return _c('div',{key:index,style:({
            marginBottom:
              index < _vm.articleData.recommendArticles.length - 1 ? '15px' : '0',
          }),on:{"click":function($event){return _vm.navTo(item.articleId)}}},[(index>0)?_c('hr'):_vm._e(),_c('div',[_c('p',{staticStyle:{"font-size":"16px","color":"#4a4a4a","margin-bottom":"7px","margin-top":"7px"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('span',{staticStyle:{"display":"flex","align-items":"center","font-size":"14px","color":"#4a4a4a"}},[_vm._v("难度： "),_c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._l((item.levelStar),function(i,k){return _c('img',{key:k + _vm.randomString(),staticStyle:{"width":"16px"},attrs:{"src":require("../../../assets/star0.png")}})}),_vm._l((5 - item.levelStar),function(i,k){return _c('img',{key:k + _vm.randomString,staticStyle:{"width":"16px"},attrs:{"src":require("../../../assets/star1.png")}})})],2)]),(item.isUncompleted)?_c('p',{staticStyle:{"background-color":"#ee2323","border-radius":"5px","padding":"1px 5px","width":"fit-content"}},[_vm._v("未完成")]):_vm._e()])])}),0)])]):_vm._e(),_c('div',{staticStyle:{"height":"20px"}}),_c('div',{staticStyle:{"text-align":"center"}},[_c('img',{staticStyle:{"width":"40%","margin":"5px"},attrs:{"src":require("../../../assets/btn_30days_plan.png")},on:{"click":function($event){return _vm.navToRouter('plan')}}}),_c('img',{staticStyle:{"width":"40%","margin":"5px"},attrs:{"src":require("../../../assets/btn_all_articles.png")},on:{"click":function($event){return _vm.navToRouter('search')}}})]),_c('div',{staticStyle:{"height":"130px"}}),_c('tabbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }