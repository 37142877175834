<template>
  <div style="margin-top: 10px">
    <button class="button" type="button" v-if="!inGetting">获取权限中</button>

    <button @click="onButtonClick" class="button" type="button" v-else>
      {{ state ? "点击结束录音" : "开始录音" }}
    </button>
    <div v-for="(value, key) in obj" :key="key">{{ key + "=>" + value }}</div>
    <div v-if="ini" style="color: black">评定中...</div>
    <div style="color: black" v-if="remark" v-html="remark"></div>
  </div>
</template>

<script>
import { wechatSharefund } from "../../../util/wechat_record1.js";
import wx from "weixin-js-sdk";
import { mapState } from "vuex";

export default {
  props: {
    stopRecord: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["safeMes", "sentence", "inGetting"]),
  },
  mounted() {
    // wechatSharefund()
    this.$store.commit("changeGettingStatus", 0);
    this.$store.dispatch("getWxAppIdAndJsapi_ticket", "record");
  },
  methods: {
    stop() {
      let that = this;
      if (!this.state) return;
      wx.stopRecord({
        fail: function (err) {
          that.$message.error(JSON.stringify(err) + "4");
          that.$emit("secondeHide");
        },
        complete: function () {
          that.state = false;
        },
      });
    },
    getRemark(result) {
      console.log("getRemark");
      var html = "";
      if (result.overall) html += "总分(overall): " + result.overall + "<br/>";
      if (result.fluency)
        html += "流利度(fluency): " + result.fluency + "<br/>";
      if (result.pronunciation)
        html += "发音(pronunciation): " + result.pronunciation + "<br/>";
      if (result.speed) html += "语速(speed): " + result.speed + "个/min<br/>";
      if (result.plosion)
        html += "爆破音(plosion): " + result.plosion + "<br/>";
      if (result.stress) html += "单词重音(stress): " + result.stress + "<br/>";
      if (result.prominence)
        html += "句子单词重读(prominence): " + result.prominence + "<br/>";
      if (result.rhythm) html += "韵律度(rhythm): " + result.rhythm + "<br/>";
      if (result.phonemes)
        html += "音素(phonemes): " + result.phonemes + "<br/>";
      if (result.integrity)
        html += "完整度(integrity): " + result.integrity + "<br/>";
      // if(result.rear_tone    ) html += '句末语调(rear tone): '      +result.rear_tone     + '<br/>';
      //     if(result.liaison      ) html += '连续(liaison): '            +result.liaison       + '<br/>';
      // this.obj = res.liasion[0]

      if (result.coherence)
        html += "逻辑准确性(coherence): " + result.coherence + "<br/>";
      if (result.phonics)
        html += "发音字母组合(phonics): " + result.phonics + "<br/>";
      if (result.words && result.words.length > 0) {
        html += "详细得分：<br/>";
        for (var i = 0; i < result.words.length; i++) {
          html +=
            "&nbsp;&nbsp;&nbsp;" +
            result.words[i].word +
            ": " +
            result.words[i].scores.overall +
            "<br/>";
        }
      }
      this.remark = html;
      console.log(this.remark);
    },

    //上传录音
    uploadVoice() {
      console.log('uploadVoice ...', this.state);
      let _this = this;
      //调用微信的上传录音接口把本地录音先上传到微信的服务器
      //不过，微信只保留3天，而我们需要长期保存，我们需要把资源从微信服务器下载到自己的服务器
      console.log(_this.localId);
      wx.uploadVoice({
        localId: _this.localId, // 需要上传的音频的本地ID，由stopRecord接口获得
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: function (res) {
          //把录音在微信服务器上的id（res.serverId）发送到自己的服务器供下载。
          _this.serverId = res.serverId;
          console.log(_this.serverId);
          let obj = {
            ..._this.safeMes,
            pid: getLocalPid(),
            wxAudioServerId: _this.serverId,
            sentence: _this.sentence,
          };
          _this.$http
            .post("/biz/oralscore", encodeURIComponent(JSON.stringify(obj)))
            .then((res) => {
              if (res.code) return _this.$message.error(res.msg);
              console.log(res.data);
              _this.ini = false;
              _this.getRemark(res.data.data);
            })
            .catch((err) => {
              _this.$message.error(err + "语音评分失败");
            });
        },
        fail: function (err) {
          _this.$message.error(JSON.stringify(err) + "上传录音失败");
          _this.ini = false;
          _this.$emit("secondeHide");
        },
      });
    },

    onButtonClick() {
      if (this.state) {
        this.doStopRecord();
      } else {
        this.doStartRecord();
      }
      this.state = !this.state;
    },

    doStartRecord() {
      let _this = this;
      wx.onVoiceRecordEnd({
        complete: function (res) {
          console.log('wx.onVoiceRecordEnd.complete', res);
          _this.onRecordSuccess(res);
        }
      });
      console.log('wx.startRecord ...', this.state);
/*      wx.startRecord({
        success: function (res) {
          console.log('wx.startRecord.success', res);
        },
        fail: function (err) {
          console.log('wx.startRecord.fail', err);
          _this.state = false;
          _this.$message.error(JSON.stringify(err));
          _this.$emit("secondeHide");
        },
        cancel: function (res) {
          console.log('wx.startRecord.cancel', res);
          _this.state = false;
          _this.$message.error("用户拒绝授权录音");
        },
      });*/
      wx.startRecord();
    },

    doStopRecord() {
      let _this = this;
      console.log('wx.stopRecord ...', this.state);
      wx.stopRecord({
        success: function (res) {
          console.log('wx.stopRecord.success', res);
          _this.onRecordSuccess(res);
        },
        fail: function (res) {
          console.log('wx.stopRecord.fail', res);
          return _this.$message({
            message: "太短了，我没听清，再说一遍吧",
            type: "warning",
          });
        },
        complete(res) {
          console.log('wx.stopRecord.complete', res);
        }
      });
    },

    onRecordSuccess(res) {
      console.log('onRecordSuccess ',res);
      this.ini = true;
      this.localId = res.localId;
      this.uploadVoice();
    }
  },

  data() {
    return {
      pid: getLocalPid(),
      state: false,
      localId: 0,
      serverId: 0,
      successVis: false,
      remark: "",
      ini: false,
      obj: {},
    };
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  width: 70%;
  height: 45px;
  background-image: linear-gradient(-58deg, #2b9af3 6%, #4bb1fa 100%);
  box-shadow: 0px 8px 14px 0px rgba(74, 144, 226, 0.3);
  border-radius: 100px;
  color: #ffffff;
  box-shadow: none;
  font-size: 17px;
  border: 0 none;
  margin: 0 auto;
  display: block;
}
.el-icon-info {
  color: #66a2ff;
}
</style>
