<template>
	<div>
		<Title></Title>
		<div class="text-center font-bold" style="padding-top: 100px;" v-if="!questions.length">
		{{waiting ? '题目获取中...' : '暂时没有题目哦!'}}	
		</div>
		<div v-if="questions.length" style="margin: 0 10px 10px 10px;padding-top: 65px;">
			<div style="padding-left: 10px;">
				<span style="color: #333;margin-left: 4px;" class="font-bold" @click="chooseSentence(-1)" :class="activeSentenceIndex == -1 ? 'active': '' " v-html="title">
					
				</span>
			</div>
		</div>
		<div v-for="(item,index) in questions">
		<!-- <div style="margin: 0 10px 10px 10px;padding-top: 65px;">
			<div style="padding-left: 10px;">
				<span style="color: #333;margin-left: 4px;" class="font-bold" @click="chooseSentence(-1)" :class="activeSentenceIndex == -1 ? 'active': '' " v-html="item.title">
					
				</span>
			</div>
		</div> -->
		<div style="margin: 20px 5px 10px 5px;padding: 10px;" class="bg-white">
			<div style="padding: 20px;font-size: 10px;background-color: #f7f7f7;border: 1px solid #ddd;line-height: 24px;" class="font-bold" v-html="item.title">
			
			</div>
			
			<!-- 题目 -->
			<problem-item :aProblem="[item.selectA,item.selectB,item.selectC,item.selectD]" :tag = "item.id + ''" :index ="index" @getAns="getAns"></problem-item>
			<div style="padding-top: 10px;padding-left: 10px;" v-if="isSubmit">
				<span style="line-height: 24px;color: #333;font-weight: bold;">
					您选择的答案： {{questions[index]['select'+answerList[index].answer]}} 正确答案：
				</span>
				<span  style="line-height: 24px;color: #333;font-weight: bold;" :style="questions[index]['select'+answerList[index].answer] == questions[index].answer ? '' : 'color:red;' ">{{questions[index].answer}}</span>	
				<br />
				<span  style="line-height: 24px;color: #333;font-weight: bold;">知识点：</span>
				<span  style="line-height: 24px;font-weight: bold;"  v-html="questions[index].explanation">
					
				</span>
			</div>
			<!-- 题目 -->
			
			
		</div>
		</div>
		<div  class="font-bold flex align-center justify-center" style="margin-top: 20px;">
			<div style="margin-bottom: 50px;padding: 8px 14px;border: 1px solid #d3d3d3;border-radius: 4px;" class="bg-white hover"  @click="backOrSubmit" :disabled="disabled" v-if="questions.length">
				{{isSubmit ? '下一篇' : '提交'}}
			</div>
			<div style="margin-bottom: 50px;padding: 8px 14px;border: 1px solid #d3d3d3;border-radius: 4px;" class="bg-white hover"  @click="backOrSubmit" :disabled="disabled" v-if="!questions.length">
				返回
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import problemItem from './problemItem.vue'
	import Title from '../title/title.vue'
	export default{
		data() {
			return {
				// 选中的句子
				activeSentenceIndex:-2,
				questions:[],
				answerList:[],
				waiting:true,
				disabled:false,
				isSubmit:false,
				title:''
			}
		},
		methods:{
			// 选中句子
			chooseSentence(index)
			{
				if(this.activeSentenceIndex == index) return this.activeSentenceIndex = -2
				this.activeSentenceIndex = index
				
			},
			// 获取题目列表
			getProblems() {
				console.log('getProblems getProblems getProblems')
				let obj = {
					...this.$route.params.obj,
					...this.safeMes
				}
				this.$http.post('/article/question/list',encodeURIComponent(JSON.stringify(obj)))
				.then(res=>{
					let data = res.data
					if(data.code) return this.$store.commit('judgeCode',{code:data.code,msg:data.msg})
					console.log(data)
					this.questions = data.data.questions
					this.waiting = false
					while(this.questions.length != this.answerList.length)
					{
						this.answerList.push('')
					}
					console.log(this.questions)
				})
				.catch(err=>{
					this.waiting = false
					this.$message.error('获取题目失败')
				})
			},
			// 得到答案数组
			getAns(data) {
				let item = {
					answer:data.ans,
					questionId:this.questions[data.index].id
				}
				this.answerList.splice(data.index,1,item)
			},
			
			// 返回或提交答案
			backOrSubmit() {
				this.disabled = true
				if(!this.questions.length)	this.$router.go(-1)
				if(this.isSubmit) {
					this.$store.commit('changeArticle',true)
					this.$router.go(-1)
				}
				else {
					let index = this.answerList.findIndex(v=>v === '')
					if(index != -1) return this.$notify({
            title: '提示',
						message: '请填写所有题目哦',
						type: 'warning',
						duration:2000,
						position: 'top-left'
          });
					let obj = {
						...this.safeMes,
						articleId:this.$route.params.obj.articleId,
						readLogId:this.$route.params.obj.readLogId,
						answers:this.answerList
						
					}
					this.$http.post('/article/question/submit',encodeURIComponent(JSON.stringify(obj)))
					.then(res=>{
						let data = res.data
						if(data.code)  return this.$store.commit('judgeCode',{code:data.code,msg:data.msg})
						this.$message.success('提交成功')
						this.disabled = false
						this.isSubmit = true
						
					})
					.catch(err=>{
						this.disabled = false
						console.log(err)
						this.$message.error('提交答案失败')
					})
				}
				
				
			}
		},
		components:{
			Title,
			problemItem
		},
		mounted() {
			if(!this.safeMes.sign) this.$store.commit('saveSafeMes')
			this.title = this.$route.params.obj.title
			this.getProblems()
		},
		computed:{
			...mapState({
				safeMes:state=>state.safeMes
			})
		}
	}
</script>

<style scoped>
	/* 句子高亮 */
	.active{
		color: white;
		background-color: #318efd;
	}
	
	.hover:hover{
		color: white;
		background-color: #318efd;
	}
</style>
