<template>
  <div class="bg-white" style="height: 100%">
    <Header :title="'注册'"></Header>
    <div style="width: 90%; margin: 20px auto 0">
      <div
        style="
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f4f4f4;
          margin-bottom: 20px;
        "
      >
        <img src="../../../assets/icon_user_login.png" />
        <input
          type="text"
          style="width: 100%"
          v-model="username"
          placeholder="请输入用户名"
        />
        <span style="width: 50px; font-size: 16px; color: #b2b2b2">必填*</span>
        <input type="text" style="position: fixed; bottom: -9999px" />
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f4f4f4;
          margin-bottom: 20px;
        "
      >
        <img src="../../../assets/icon_mima_login.png" />
        <input
          type="text"
          style="width: 100%"
          v-model="password"
          placeholder="6-16位密码（字母、数字或下划线）"
        />
        <span style="width: 50px; font-size: 16px; color: #b2b2b2">必填*</span>
        <input type="text" style="position: fixed; bottom: -9999px" />
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f4f4f4;
          margin-bottom: 20px;
        "
      >
        <img src="../../../assets/icon_dianhua_login.png" />
        <input
          type="text"
          style="width: 100%"
          v-model="phone"
          placeholder="请输入手机号"
        />
        <input type="text" style="position: fixed; bottom: -9999px" />
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f4f4f4;
          margin-bottom: 20px;
        "
      >
        <img src="../../../assets/icon_nicheng_login.png" />
        <input
          type="text"
          style="width: 100%"
          v-model="nickname"
          placeholder="请输入你的昵称"
        />
        <span style="width: 50px; font-size: 16px; color: #b2b2b2">必填*</span>
        <input type="text" style="position: fixed; bottom: -9999px" />
      </div>

      <div
        style="
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f4f4f4;
          margin-bottom: 20px;
        "
      >
        <img src="../../../assets/icon_email_login.png" />
        <input
          type="text"
          style="width: 100%"
          v-model="email"
          placeholder="请输入你的邮箱"
        />
        <input type="text" style="position: fixed; bottom: -9999px" />
      </div>
      <div style="display: flex; justify-content: space-around">
        <div
          style="height: 60px; display: flex; align-items: center"
          @click="gender = 'M'"
        >
          <div
            v-show="gender === 'F'"
            :style="{
              backgroundImage: 'url(' + man1 + ')',
            }"
            style="
              background-color: #f5f8f9;
              background-repeat: no-repeat;
              border-radius: 50%;
              width: 60px;
              height: 100%;
              display: flex;
              align-items: center;
              background-size: 35px 35px;
              background-position: center;
              margin-right: 10px;
            "
          ></div>
          <div
            v-show="gender === 'M'"
            :style="{
              backgroundImage: 'url(' + man + ')',
            }"
            style="
              background-color: #3fa8f7;
              background-repeat: no-repeat;
              border-radius: 50%;
              width: 60px;
              height: 100%;
              display: flex;
              align-items: center;
              background-size: 35px 35px;
              background-position: center;
              margin-right: 10px;
            "
          ></div>
          男
        </div>
        <div
          style="height: 60px; display: flex; align-items: center"
          @click="gender = 'F'"
        >
          <div
            v-show="gender === 'M'"
            :style="{
              backgroundImage: 'url(' + woman1 + ')',
            }"
            style="
              background-color: #f5f8f9;
              background-repeat: no-repeat;
              border-radius: 50%;
              width: 60px;
              height: 100%;
              display: flex;
              align-items: center;
              background-size: 35px 35px;
              background-position: center;
              margin-right: 10px;
            "
          ></div>
          <div
            v-show="gender === 'F'"
            :style="{
              backgroundImage: 'url(' + woman + ')',
            }"
            style="
              background-color: #3fa8f7;
              background-repeat: no-repeat;
              border-radius: 50%;
              width: 60px;
              height: 100%;
              display: flex;
              align-items: center;
              background-size: 35px 35px;
              background-position: center;
              margin-right: 10px;
            "
          ></div>
          女
        </div>
      </div>
      <button
        type="button"
        @click="toRegister"
        :disabled="disabled"
        style="margin-top: 20px"
      >
        注册
      </button>
    </div>
  </div>
</template>

<script>
import Header from "../../reading/title/header.vue";
export default {
  components: {
    Header,
  },
  methods: {
    /* 注册 */
    toRegister() {
      let obj = {
        pid: getLocalPid(),
        username: this.username,
        password: this.password,
        nickname: this.nickname,
        mobile: this.phone,
        email: this.email,
        gender: this.gender,
      };
      this.disabled = true;
      this.$http
        .post("/user/register", encodeURIComponent(JSON.stringify(obj)))
        .then((res) => {
          let data = res.data;
          this.disabled = false;
          if (data.code != 0) return this.$message.error(data.msg);
          this.$store.commit("login", data.data);
          localStorage.setItem("ways", "login");
          // 把token保存到本地
          let msg = JSON.stringify(data.data);
          // userMes中有userId和token
          window.localStorage.setItem("userMes", msg);
          localStorage.setItem("ways", "username");
          this.$message.success("注册成功");
          this.$router.push({ name: "schoolMes", query: { type: 123 } });
        })
        .catch(() => {
          this.disabled = false;
        });
    },

    // 性别选择改变
    genderChange(e) {
      this.gender = e.target.value;
    },
  },
  data() {
    return {
      man: require("../../../assets/man.png"),
      woman: require("../../../assets/woman.png"),
      man1: require("../../../assets/man1.png"),
      woman1: require("../../../assets/woman1.png"),
      disabled: false,
      username: "",
      password: "",
      phone: "",
      nickname: "",
      email: "",
      gender: "M",
    };
  },
};
</script>

<style scoped>
input {
  border: 0 none;
  outline: none;
  background: #ffffff;
  font-size: 16px;
  color: #181818;
  padding: 10px;
}
input::-webkit-input-placeholder {
  color: #b2b2b2;
  font-size: 16px;
}
button {
  width: 100%;
  height: 55px;
  background-image: linear-gradient(-58deg, #2b9af3 6%, #4bb1fa 100%);
  box-shadow: 0px 8px 14px 0px rgba(74, 144, 226, 0.3);
  border-radius: 100px;
  color: #ffffff;
  box-shadow: none;
  font-size: 17px;
  border: 0 none;
}
</style>
