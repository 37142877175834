<template>
  <div class="bg-white" style="height: 100%">
    <Header :title="'阅读中心'"></Header>
    <div style="padding: 20px">
      <div
        style="
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          border-bottom: 1px solid #ddebff;
        "
      >
        <div
          style="
            width: 65px;
            height: 65px;
            margin-right: 15px;
            background-image: linear-gradient(-41deg, #2b9af3 0%, #4bb1fa 100%);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            color: #ffffff;
            font-size: 13px;
          "
        >
          <img
            src="../../../assets/clock.png"
            style="width: 45%; margin-bottom: 5px"
          />
          {{ timeFomat }}
        </div>
        <div style="flex: 1">
          <div style="margin-bottom: 5px">ID：{{ userId }}</div>
          <div style="display: flex; align-items: center">
            <span
              style="
                font-size: 23px;
                color: rgb(38, 158, 253);
                display: flex;
                align-items: flex-start;
              "
              >{{ userInfo.level
              }}<i style="font-size: 12px; font-style: normal; margin: 0 5px"
                >level</i
              ></span
            >
            <span class="css2">
              <span
                class="css1"
                :style="{ width: (userInfo.level / 396) * 100 + '%' }"
              ></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <title-label :title="title" :labelList="wordExplains"></title-label>
    <div style="padding: 0 20px">
      <div
        style="
          color: #181818;
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 20px;
        "
      >
        {{ title }}
      </div>
      <div
        ref="content"
        id="content-wrapper"
        style="
          color: #4a4a4a;
          line-height: 22px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: keep-all;
          white-space: pre-line;
        "
        v-html="content"
        @click="popVis"
      ></div>
    </div>

    <!-- 弹出层 -->
    <popup
      :menu="menu"
      :width="280"
      ref="popup"
      :sentence="currenSentence"
      @menuItemClick="menuItemClick"
      :articleId="articleId"
      :readLogId="readLogId"
      @noPopup="noPopup"
      @close="hideTextColorHandler"
    />
    <!-- 弹出层 -->

    <!-- 哪里不懂点哪里 -->
    <!-- click-where v-if="!toHide"></click-where -->
    <click-where></click-where>
    <!-- 哪里不懂点哪里 -->

    <!-- 三个按钮 -->
    <div class="text-center font-bold" style="padding-top: 20px" v-if="getting">
      获取中...
    </div>
    <div class="flex align-center justify-center" style="margin-top: 20px">
      <!-- button
        style="
          background-color: #3d91f7;
          padding: 8px 12px;
          border: none;
          border-radius: 4px;
        "
        class="text-white font-bold"
        @clickbak="navTo('/practice')"
        @click="bShowQuestions=true"
      >
        练一练
      </button -->
      <button
        style="
          background-color: #ec5f74;
          padding: 8px 12px;
          border: none;
          border-radius: 4px;
          margin: 0 5px;
        "
        class="text-white font-bold"
        @click="changeArticle('tooHard')"
      >
        看不懂
      </button>
      <button
        style="
          background-color: #9adfbf;
          padding: 8px 12px;
          border: none;
          border-radius: 4px;
        "
        class="text-white font-bold"
        @click="changeArticle('changeSubject')"
      >
        换主题
      </button>
    </div>
    <!-- 三个按钮 -->

    <div
      style="
        box-sizing: border-box;
        height: 1px;
        width: 99%;
        border-bottom: 1px solid #9a9a9a;
        margin: 20px auto 5px;
      "
    ></div>

    <Questions v-if="bShowQuestions" :articleId="articleId" :readLogId="readLogId" :timestamp="timestamp"></Questions>

    <div
      class="text-center"
      style="
        font-size: 4px;
        font-style: italic;
        color: #ababab;
        padding-bottom: 150px;
      "
    >
      标识号: 09383181-F271-G153-S162-W271-3522
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
import { Highlight, removeActive } from "../../../util/Highlight";
import popup from "../popup/popup.vue";
import { mapState, mapMutations } from "vuex";
import clickWhere from "./click-where.vue";
import tabbar from "../../tabbar/tabbar.vue";
import Header from "../title/header.vue";
import Questions from "./questions";
import titleLabel from "./title_label.vue";
import { wechatSharefund } from "../../../util/wechat_record1.js";
export default {
  components: {
    Header,
    tabbar,
    clickWhere,
    popup,
    titleLabel,
    Questions
  },
  mounted() {
    this.$store.commit("saveArticleId", this.$route.query.id);
    let title = window.localStorage.getItem("title");
    if (title) document.title = title;
    if (!this.safeMes.sign) this.$store.commit("saveSafeMes");
    this.getArticleData();
    const t = setInterval(() => {
      if (this.countdown > 0) {
        this.time = this.countdown;
        this.timerMinus();
        clearInterval(t);
      }
    }, 500);
  },
  methods: {
    timerMinus() {
      setInterval(() => {
        this.time--;
      }, 1000);
    },
    onContentContainerLoad(){
      let arr = this.$refs.content.getElementsByTagName('IMG');
      for(let i=0; i<arr.length; i++) {
        arr[i].style.width = '100%';
        arr[i].style.height = 'auto';
        arr[i].style.maxWidth = '100%';
        arr[i].style.justifyContent = 'center';
      }
    },
    // 获取文章详情数据 与vuex中的articleData不同
    getArticleData() {
/*      if (this.isChangeArticle) {
        this.$store.commit("changeArticle", false);
        this.changeArticle("next");
        return;
      }*/

      let id = this.$route.query.id;
      if (!id) id = localStorage.getItem("articleId");
      else {
        localStorage.setItem("articleId", id);
      }
      this.articleId = id;
      let obj = {
        ...this.safeMes,
        articleId: id,
        pid: getLocalPid(),
      };
      Promise.all([
        this.$http.post(
          "/article/enter",
          encodeURIComponent(JSON.stringify(obj))
        ),
        this.$http.post(
          "/article/aar",
          encodeURIComponent(JSON.stringify(obj))
        ),
      ])
      .then((res) => {
        let data = res[0].data;
        if (data.code)
          return this.$store.commit("judgeCode", {
            code: data.code,
            msg: data.msg,
          });
        let ret = data.data;
        this.content = ret.content;
        this.readLogId = ret.readLogId;
        this.countdown = ret.countdown;
        this.timestamp = ret.timestamp;
        this.keepOrigStyles = ret.keepOrigStyles;
        this.title = ret.title;
        this.$store.commit("saveArticleTitle", this.title);

        // 如果用户登录了 ↓ 分享↓
        if (this.safeMes.sign) {
          if (!this.jsApiTicket) {
            this.$store.dispatch("getWxAppIdAndJsapi_ticket");
          } else {
            wechatSharefund();
          }
        }
        // 分享 ↑

        this.wordExplains = ret.wordExplains;
        this.maxLevelSentences = res[1].data.data.maxLevelSentences || []; // 超纲句子
        this.maxLevelWords = res[1].data.data.maxLevelWords || []; // 超纲词汇
        this.outStandardWords = res[1].data.data.outStandardWords || [];
        this.maxLevelSentences = this.maxLevelSentences.map((item) => {
          const prefix = item.split(/[\\.|\\?|!|！|？|。]\s*["|”]/)[0];
          let sss = "";
          // 防止返回数据不准确，以句号截断，取一段长度，又怕文中有句号截短了。如果文本大于原文本的2/3，那就用这个阶段的文本判断，否则用原句
          if (prefix.length > (item.length / 4) * 3) {
            sss = prefix;
          } else {
            sss = item;
          }
          return sss;
        });
        console.log(this.maxLevelSentences);
        console.log(this.maxLevelWords);
        console.log(this.outStandardWords);
        this.$nextTick(function () {
          this.onContentContainerLoad();
          Highlight(
            this.$refs.content,
            this.maxLevelSentences,
            this.maxLevelWords,
            this.outStandardWords
          );
          this.bShowQuestions = true;
        });
      })
      .catch(() => {
        return this.$message.error("获取文章内容失败");
      });
    },
    // 复制
    copyHandler(txt = "") {
      let iscopy = false;
      if (document) {
        let textArea = document.createElement("textarea");
        textArea.style.position = "fixed";
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = "2em";
        textArea.style.height = "2em";
        textArea.style.padding = 0;
        textArea.style.border = "none";
        textArea.style.outline = "none";
        textArea.style.boxShadow = "none";
        textArea.style.background = "transparent";
        textArea.value = txt;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand("copy");
          iscopy = true;
        } catch (err) {
          console.log("不能使用这种方法复制内容" + err.toString());
        }
        document.body.removeChild(textArea);
      }
      return iscopy;
    },
    // 朗读
    speakHandler(content) {
      let id = this.$route.query.id;
      if (!id) id = localStorage.getItem("articleId");
      else {
        localStorage.setItem("articleId", id);
      }

      this.articleId = id;
      let obj = {
        ...this.safeMes,
        articleId: id,
        readLogId: this.readLogId,
//      content: encodeURI(content),
        content: content,
      };
      this.$http
        .post("/biz/tts", encodeURIComponent(JSON.stringify(obj)))
        .then(({ data: { code, msg } }) => {
          if (code === 0) {
            // 成功: todo
            //  			"type": "word|sentence",
            // "audioUrl": "https://api.bluebirdabc.com/media/tts/tts_-1840647503.mp3",
          } else {
            this.$message.error(msg || "操作失败！");
          }
        });
    },
    // 朗读
    remarkHandler() {
      let id = this.$route.query.id;
      if (!id) id = localStorage.getItem("articleId");
      else {
        localStorage.setItem("articleId", id);
      }

      this.articleId = id;
      let obj = {
        ...this.safeMes,
        articleId: id,
        cardNumber: "", // 充值卡号， 功能后延
      };
      this.$http
        .post("/biz/oralscore", encodeURIComponent(JSON.stringify(obj)))
        .then(({ data: { code, msg } }) => {
          if (code !== 0) {
            this.$message.error(msg || "操作失败！");
          }
        });
    },
    menuItemClick({ event }) {
      if (event === "search") {
        // 单词典, 不知需求，暂时当作读的操作
        this.speakHandler(this.currentText);
      } else if (event === "copy") {
        // 复制
        const isOk = this.copyHandler(this.currenSentence);
        if (isOk) {
          this.$message.success("复制成功！");
        }
      } else if (event === "speak") {
        // 智能朗读
        this.speakHandler(this.currenSentence);
      } else if (event === "remark") {
        // 语音评分
        this.remarkHandler();
      }
    },
    // 弹出层
    popVis(e) {
      // 如果没用点击到dom或者点击的不是单词，就不执行
      if (
        !e.target ||
        !e.target.classList.contains("word") ||
        !e.target.textContent.trim()
      ) {
        return;
      }
      const parent = e.target.parentNode;
      if (parent && parent.childNodes) {
        this.parentNode = parent;
        if (this.keepOrigStyles) {
          this.currenSentence = [...parent.childNodes].reduce((t, v) => {
            const s = v.childNodes[0] && v.childNodes[0].nodeValue;
            if (s) {
              t += s;
            }
            return t;
          }, "");
        } else {
          this.currenSentence = parent.textContent.trim();
        }
      }
      const text = e.target.childNodes[0].nodeValue;
      this.currentText = text || "";
      this.toHide = true;
      this.$refs.popup.show(e.pageX + 15, e.pageY + 10);
    },

    // 跳转
    navTo(to) {
      if (to == "/practice") {
        let obj = {
          timestamp: this.timestamp,
          readLogId: this.readLogId,
          articleId: this.articleId,
          title: this.title,
        };
        this.$router.push({ name: "practice", params: { obj } });
      }
    },

    // 太难 换个主题
    changeArticle(type) {
      let obj = {
        ...this.safeMes,
        articleId: this.articleId,
        readLogId: this.readLogId,
        timestamp: this.timestamp,
        reason: type,
        pid: getLocalPid(),
      };
      this.getting = true;
      this.$http
        .post("/article/next", encodeURIComponent(JSON.stringify(obj)))
        .then((res) => {
          let data = res.data;
          if (data.code) {
            return this.$store.commit("judgeCode", {
              code: data.code,
              msg: data.msg,
            });
          }
          let ret = data.data;
          let href = window.location.href;
          window.location.href =
            href.substring(0, href.lastIndexOf("/")) +
            "/article?id=" + ret.articleId +
            "&pid=" + getLocalPid();
        })
        .catch(() => {
          this.getting = false;
          return this.$message.error("获取文章失败");
        });
    },

    // 显示哪里不会点哪里
    noPopup() {
      this.toHide = false;
    },
    hideTextColorHandler() {
      removeActive();
    },
    ...mapMutations(["changeSentence", "changeWord", "changeOffset"]),
  },
  data() {
    return {
      pid: getLocalPid(),
      time: 0,
      keepOrigStyles: "", // 是否保留样式
      maxLevelSentences: [], // 超纲句子
      maxLevelWords: [], // 超纲词汇
      outStandardWords: [], // 不知啥词汇
      sourceArr: [], // 将文章以句号问号感叹号切断成数组
      sourceArticle: "", // 不含标签的原文
      parentNode: null,
      currentText: "", // 当前单词
      currenSentence: "", // 当前句子
      // 文章内容
      content: "",
      // 倒计时
      countdown: 0,
      timestamp: 0,
      title: "",
      readLogId: 0,
      // 单词解释
      wordExplains: [],
      articleId: 0,
      // 控制哪里不会点哪里的显示
      toHide: false,

      bShowQuestions: false,

      // 弹出层的菜单
      menu: [
        {
          name: "单词翻译",
          event: "search",
        },
        {
          name: "语法分析",
          event: "analyse",
        },
        {
          name: "句子翻译",
          event: "translate",
        },
        {
          name: "智能朗读",
          event: "speak",
        },
        {
          name: "语音评分",
          event: "record",
        },
        {
          name: "复制句子",
          event: "copy",
        },
        {
          name: "名师在线",
          event: "teacher",
        },
        {
          name: "更多资源",
          event: "more",
        },
      ],

      getting: false,
      imgVis: false,
    };
  },
  computed: {
    ...mapState({
      safeMes: (state) => state.safeMes,
      wxAppId: (state) => state.wxAppId,
      jsApiTicket: (state) => state.jsApiTicket,
      isChangeArticle: (state) => state.isChangeArticle,
      userInfo: (state) => state.userInfo,
      userId: (state) => state.userId,
    }),
    timeFomat() {
      if (this.time < 0) return "0:00";
      if (this.time % 60 < 10) {
        return Math.floor(this.time / 60) + " : 0" + (this.time % 60);
      }
      return Math.floor(this.time / 60) + ":" + (this.time % 60);
    },
    getPopHeight() {
      return (this.menu.length / 4) * 50;
    },
    offset({ currentText, currenSentence }) {
      // 单词距离句子offset
      if (!currentText || !currenSentence) return -1;
      return currenSentence.indexOf(currentText);
    },
  },
  watch: {
    currenSentence(v) {
      this.changeSentence(v);
    },
    currentText(v) {
      this.changeWord(v);
    },
    offset(v) {
      this.changeOffset(v);
    },
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
}
::v-deep .sentence {
  font-size: 20px;
}
::v-deep .sentence .word {
  font-size: 20px;
}
</style>
