import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../components/reading/index/index.vue'
import my from '../components/my/my/my.vue'
import article from '../components/reading/article/article123.vue'
import photo from '../components/reading/photo/photo.vue'
import schoolMes from '../components/my/schoolMes/schoolMes.vue'
import ability from '../components/my/ability/ability.vue'
import recharge from '../components/my/recharge/recharge.vue'
import weChat from '../components/my/weChat/weChat.vue'
import login from '../components/my/login/login.vue'
import register from '../components/my/register/register.vue'
import before from '../components/my/before/before.vue'
import practice from '../components/reading/practice/practice.vue'
import teacher from '../components/reading/teacher/teacher.vue'
import record from '../components/reading/record/record.vue'
import plan from '../components/reading/index/plan.vue'
import search from '../components/reading/index/search.vue'
import home from '../components/home/index'
// import photo1 from '../components/reading/photo/photo_try.vue'
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/index',
	},
	{
		path: '/home',
		name: 'home',
		component: home
	},
	{
		path: '/index',
		name: 'index',
		component: index
	},
	{
		path: '/my',
		name: 'my',
		component: my
	},
	{
		path: '/article',
		name: 'article',
		component: article
	},
	{
		path: '/photo',
		name: 'photo',
		component: photo
	},
	{
		path: '/schoolMes',
		name: 'schoolMes',
		component: schoolMes
	},
	{
		path: '/ability',
		name: 'ability',
		component: ability
	},
	{
		path: '/recharge',
		name: 'recharge',
		component: recharge
	},
	{
		path: '/weChat',
		name: 'weChat',
		component: weChat
	},
	{
		path: '/register',
		name: 'register',
		component: register
	},
	{
		path: '/login',
		name: 'login',
		component: login
	},
	{
		path: '/before',
		name: 'before',
		component: before
	},
	{
		path: '/practice',
		name: 'practice',
		component: practice
	},
	{
		path: '/teacher',
		name: 'teacher',
		component: teacher
	},
	{
		path: '/record',
		name: 'record',
		component: record
	},
	{
		path: '/plan',
		name: 'plan',
		component: plan
	},
	{
		path: '/search',
		name: 'search',
		component: search
	}
]

// 历史模式
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
router.beforeEach((to, from, next) => {
	let token = window.localStorage.getItem('userMes') ? JSON.parse(localStorage.getItem('userMes')).token : ''

	let initSpan = getInitSpanMillis();
	if(!token && initSpan<10*60*1000 && (to.name === 'photo' || to.name === 'article') ) {
		token = 'protected-token';
	}

	if (!token && (to.name !== 'home' && to.name !== 'login' && to.name !== 'register' && to.name !== 'before' && to.name !== 'weChat')) {
		next({
			name: 'home', query: {pid: getLocalPid()}
		});
	} else if (!token && (to.name === 'home' || to.name === 'login' || to.name === 'register' || to.name === 'before' || to.name === 'weChat')) {
		next()
	} else if (token && to.name === 'home') {
		next()
	} else if (token && (to.name === 'login' || to.name === 'register' || to.name === 'before' || to.name === 'weChat')) {
		next({
			name: 'index', query: {pid: getLocalPid()}
		})
	} else {
		next()
	}
})
export default router
