<template>
  <div style="padding: 0 20px 20px">
    <div
      v-for="(item, index) in labelList"
      :key="index"
      class="inline-block txt"
      style="margin-right: 5px"
      :style="
        activeLabelIndex == index
          ? 'background-color: #1677fe; border-radius: 5px; color: #ffffff'
          : ''
      "
      @click="changeIndex(index)"
    >
      <div>{{ item.word }}</div>
    </div>
    <div
      v-if="activeLabelIndex !== -1"
      style="background: aliceblue; padding: 5px; margin: 10px 0"
    >
      <div style="margin-top: 3px; margin-bottom: 3px">
        {{ labelList[activeLabelIndex].explain }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labelList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    // 选中标签
    changeIndex(index) {
      if (this.activeLabelIndex == index) return (this.activeLabelIndex = -1);
      this.activeLabelIndex = index;
    },
  },
  data() {
    return {
      // 选中的标签
      activeLabelIndex: -1,
    };
  },
};
</script>

<style scoped>
.txt {
  padding: 5px;
}
</style>
