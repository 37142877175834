<template>
  <div
    id=""
    style="height: 100vh; background-color: #f7f7f7; padding-top: 55px"
  >
    <Title></Title>
    <div
      style="
        width: 98%;
        margin: 0 auto;
        box-shadow: 0 0 0.5em #ddd;
        border-radius: 0.5em;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
      "
      class="bg-white"
    >
      <div
        id=""
        class="text font-bold text-center line-height"
        style="
          padding: 5px;
          margin-top: 10px;
          margin-right: 10px;
          background-color: #f7f7f7;
        "
      >
        微信登录
      </div>

      <div id="" class="line-height">使用微信的方式登录</div>
      <div
        class="font-bold text-center"
        style="
          background-color: #66afe9;
          color: white;
          width: 99%;
          padding: 5px;
          border-radius: 0.3em;
          margin-top: 5px;
        "
        @click="login()"
        :disabled="disabled"
      >
        {{ disabled ? "获取appid中..." : "登录" }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Title from "../../reading/title/title.vue";
export default {
  components: {
    Title,
  },
  methods: {
    // 微信登录
    login() {
      // 去授权 然后重定向
      let wxRedirectUrl = "https://read.bluebirdabc.com/readapi/user/wxoauthcb";
      let state = this.pid + ":" + this.wxAppId + ":" + window.location.href;
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize" +
        "?appid=" +
        this.wxAppId +
        "&redirect_uri=" +
        wxRedirectUrl +
        "&response_type=code" +
        "&scope=snsapi_userinfo" +
        "&state=" +
        state +
        "#wechat_redirect";
    },
    // 获取appid
    getWxAppId() {
      this.disabled = true;
      this.$http
        .get("/partner/get/wxappid?pid=" + getLocalPid())
        .then((res) => {
          let msg = res.data;
          if (msg.code != 0) return this.$message.error(msg.msg);
          this.$store.commit("saveAppId", msg.data.wxAppId);
          this.disabled = false;
        })
        .catch((err) => {
          this.$message.error("请求appid失败");
          console.log(err);
          this.disabled = false;
        });
    },
  },
  data() {
    return {
      activeChecked: 0,
      active: false,
      url: "",
      msg: "",
      disabled: false,
      urll: "",
      pid: getLocalPid(),
    };
  },
  computed: {
    ...mapState({
      wxAppId: (state) => state.wxAppId,
    }),
  },
  mounted() {
    if (!this.wxAppId) this.getWxAppId();
    console.log(location.href, "当前地址");
    let url = window.location.href;
    let theRequest = {};
    // 发现url中含有token和userId
    if (url.indexOf("?") != -1) {
      url = url.split("?")[1];
      let strs = url.split("&");
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
      }
      let msg = JSON.stringify(theRequest);

      this.msg = msg;
      console.log(theRequest, "msg");
      this.$message.success("登录成功");
      localStorage.setItem("ways", "wechat");
      window.localStorage.setItem("userMes", msg);
      this.$store.commit("login", theRequest);
    }
    this.url = location.href;
  },
};
</script>

<style scoped>
.text {
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  font-size: 17px;
  font-weight: bold;
  background-color: #f5f5f5;
  border-radius: 0.1em;
}
.line-height {
  line-height: 24px;
}

input,
button,
select {
  border: 1px solid #ddd;
  border-radius: 0.3em;
  outline: 0;
  padding: 0.5em 0.5em;
  background-color: white;
  color: #555;
  font-size: 1em;
}
.active {
  box-shadow: 0.1px 0.1px 4px 0.5px rgba(49, 142, 253, 0.4);
}
</style>
