<template>
  <div id="" style="width: 100%">
    <div
      v-if="!src"
      style="padding: 100px; text-align: center; font-weight: bold"
    >
      获取中...
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  methods: {
    navTo() {
      this.$router.go(-1);
    },
  },
  data() {
    return {
      src: "",
      pid: getLocalPid(),
    };
  },
  mounted() {
    let obj = {
      ...this.safeMes,
      pid: getLocalPid(),
    };
    this.$http
      .post("/biz/url/guide", encodeURIComponent(JSON.stringify(obj)))
      .then((res) => {
        let data = res.data;
        if (data.code)
          return this.$store.commit("judgeCode", {
            code: data.code,
            msg: data.msg,
          });

        this.src = data.data;
        location.href = this.src;
      })
      .catch((err) => {
        console.log(err);
        return this.$message.error("获取失败");
      });
  },
  computed: {
    ...mapState({
      safeMes: (state) => state.safeMes,
    }),
  },
};
</script>

<style scoped>
img {
  width: 100%;
}
</style>
