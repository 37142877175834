<template>
  <div
    class="position-fiexd position-relative"
    id="source"
    draggable="true"
    @mousedown="dragStart"
    @mousemove="dragMove"
    @mouseup="dragEnd"
    @dragstart="dragStart"
    @dragmove="dragMove"
    @dragend="dragEnd"
    @touchstart="dragStart"
    @touchmove="dragMove"
    @touchend="dragEnd"
    style="z-index: 10; display: flex; align-items: center"
    :style="'top:' + top + ';left:' + left + ';'"
  >
    <img src="../../../assets/help.png" style="" />
  </div>
</template>

<script>
export default {
  methods: {
    dragStart(e) {
      e.preventDefault();
      this.isDragging = true;
      let ele = document.getElementById("source");
      if (e.type == "touchstart") {
        let _e = e.changedTouches[0];
        this.diffX = _e.clientX - ele.offsetLeft;
        this.diffY = _e.clientY - ele.offsetTop;
      }
      else if(e.type == "mousedown") {
        this.diffX = e.clientX - ele.offsetLeft;
        this.diffY = e.clientY - ele.offsetTop;
      }
    },
    dragMove(e) {
      if(!this.isDragging)
        return;
      e.preventDefault();
      if (e.type == "touchmove") {
        let _e = e.changedTouches[0];
        this.left = (_e.clientX-this.diffX) + "px";
        this.top  = (_e.clientY-this.diffY) + "px";
      }
      else if(e.type == "mousemove") {
        this.left = (e.clientX-this.diffX) + "px";
        this.top  = (e.clientY-this.diffY) + "px";
      }
    },
    dragEnd(e) {
      this.isDragging = false;
    },
  },
  data() {
    return {
      isDragging: false,
      diffX:0,
      diffY:0,
      top: "30%",
      left: "55%",
    };
  },
};
</script>

<style>
</style>
