<template>
  <div class="bg-white" style="height: 100%">
    <Header :title="'个人中心'"></Header>
    <div
      style="
        height: 156px;
        background: #ddebff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
      "
    >
      <div
        style="
          width: 76px;
          height: 76px;
          border-radius: 50%;
          border: 4px solid #cfe4fe;
          margin-right: 15px;
        "
      >
        <div
          style="
            width: 68px;
            height: 68px;
            border-radius: 50%;
            border: 4px solid #b7d9fd;
          "
        >
          <img
            v-if="!userInfo.avatar"
            src="../../../../public/img/author.png"
            style="width: 58px; height: 58px; border-radius: 50%"
          />
          <img
            v-else
            :src="userInfo.avatar"
            style="width: 58px; height: 58px; border-radius: 50%"
          />
        </div>
      </div>

      <div class="info">
        <div
          style="
            font-size: 18px;
            color: rgb(11, 78, 131);
            border-bottom: 1px solid rgb(221, 235, 255);
            line-height: 35px;
            padding: 0 15px;
          "
        >
          {{ token ? userInfo.nickname : "未登录" }}
        </div>
        <div style="padding: 10px 15px; font-size: 14px; color: #0b4e83">
          <span>ID: {{ userId + "" }}</span>
          <span
            >年级：{{
              grade.filter((item) => item.value === userInfo.grade)[0]
                ? grade.filter((item) => item.value === userInfo.grade)[0].name
                : ""
            }}</span
          >
          <div style="display: flex; align-items: center">
            阅读级别：<span class="css2">
              <span
                class="css1"
                :style="{ width: (userInfo.level / 396) * 100 + '%' }"
              ></span>
            </span>
            {{ userInfo.level }}
          </div>
        </div>
        <div class="infoArrow"></div>
      </div>
    </div>
    <div style="background: #ffffff; padding: 15px">
      <div
        style="
          text-align: center;
          color: #b2b2b2;
          font-size: 14px;
          margin-bottom: 10px;
        "
      >
        <div>服务到期时间：{{userInfo.service_end_date}}</div>
      </div>
      <div class="item" @click="$router.push('/schoolMes')">
        <span>设置学校信息</span>
        <img src="../../../assets/arrow-right.png" style="width: 25px" />
      </div>
      <div class="item" @click="$router.push('/ability')">
        <span>设置阅读能力</span>
        <img src="../../../assets/arrow-right.png" style="width: 25px" />
      </div>
      <div class="item" @click="$router.push('/recharge')">
        <span>卡密充值</span>
        <img src="../../../assets/arrow-right.png" style="width: 25px" />
      </div>
    </div>
    <button type="button" v-if="token" @click="exit()" style="margin-top: 20px">
      退出登录
    </button>
    <tabbar />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "../../reading/title/header.vue";
import tabbar from "@/components/tabbar/tabbar.vue";
import router from "@/router";
export default {
  components: {
    tabbar,
    Header,
  },
  data() {
    return {
      grade: [
        { name: "小一上 (0起点)", value: "X1s" },
        { name: "小一下", value: "X1x" },
        { name: "小二上", value: "X2s" },
        { name: "小二下", value: "X2x" },
        { name: "小三上", value: "X3s" },
        { name: "小三下", value: "X3x" },
        { name: "小四上", value: "X4s" },
        { name: "小四下", value: "X4x" },
        { name: "小五上", value: "X5s" },
        { name: "小五下", value: "X5x" },
        { name: "小六上", value: "X6s" },
        { name: "小六下", value: "X6x" },
        { name: "初一上", value: "C1s" },
        { name: "初一下", value: "C1x" },
        { name: "初二上", value: "C2s" },
        { name: "初二下", value: "C2x" },
        { name: "初三上", value: "C3s" },
        { name: "初三下", value: "C3x" },
        { name: "高一上", value: "G1s" },
        { name: "高一下", value: "G1x" },
        { name: "高二上", value: "G2s" },
        { name: "高二下", value: "G2x" },
        { name: "高三上", value: "G3s" },
        { name: "高三下", value: "G3x" },
        { name: "大一上", value: "B1s" },
        { name: "大一下", value: "B1x" },
        { name: "大二上", value: "B2s" },
        { name: "大二下", value: "B2x" },
        { name: "大三上", value: "B3s" },
        { name: "大三下", value: "B3x" },
        { name: "大四上", value: "B4s" },
        { name: "大四下", value: "B4x" },
        { name: "研一上", value: "Y1s" },
        { name: "研一下", value: "Y1x" },
        { name: "研二上", value: "Y2s" },
        { name: "研二下", value: "Y2x" },
        { name: "研三上", value: "Y3s" },
        { name: "研三下", value: "Y3x" },
        { name: "研四上", value: "Y4s" },
        { name: "研四下", value: "Y4x" },
      ],
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      userId: (state) => state.userId,
      userInfo: (state) => state.userInfo || {},
    }),
  },
  mounted() {
    if (!this.token) this.getToken();
    if (this.token && !this.userInfo.nickname) {
      this.$store.dispatch("getUserInfo", "article");
    }
    let title = window.localStorage.getItem("title");
    if (title) document.title = title;
  },
  methods: {
    getToken() {
      this.$store.commit("init");
    },
    exit() {
      this.$store.commit("exit");
      this.$router.push({ name: "home", query: { pid: getLocalPid() } });
    },
  },
};
</script>

<style scoped>
.info {
  width: 70%;
  height: 100px;
  background-color: #fdfeff;
  border-radius: 20px;
  position: relative;
  border: 1px solid #ddebff;
}
.infoArrow {
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-left: 0px solid transparent;
  border-right: 15px solid #fdfeff;
  border-bottom: 10px solid transparent;
  position: absolute;
  left: -15px;
  top: 40px;
}
.css1 {
  background-image: linear-gradient(-58deg, #2b9af3 6%, #4bb1fa 100%);
  display: inline-block;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
}
.css2 {
  width: 43%;
  height: 11.08px;
  background: rgb(151, 209, 255);
  border-radius: 10px;
  display: inline-block;
  position: relative;
  font-size: 14px;
  margin-right: 5px;
}
.item {
  display: flex;
  font-size: 16px;
  color: #181818;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f4;
  padding: 10px 0;
}
button {
  width: 80%;
  height: 55px;
  margin: 0 auto;
  background-image: linear-gradient(-58deg, #2b9af3 6%, #4bb1fa 100%);
  box-shadow: 0px 8px 14px 0px rgba(74, 144, 226, 0.3);
  border-radius: 100px;
  color: #ffffff;
  box-shadow: none;
  font-size: 17px;
  border: 0 none;
  display: block;
}
</style>
