<template>
  <div class="bg-white" style="height: 100%">
    <Header :title="'学校信息修改'"></Header>
    <div
      style="
        font-size: 20px;
        color: rgb(11, 78, 131);
        background: rgb(221, 235, 255);
        display: flex;
        align-items: center;
        padding: 25px;
      "
    >
      选择班级是为了快速初步定位你的阅读等级<br />超级AI会在这个基础上进行算法调整
    </div>
    <div style="width: 90%; margin: 20px auto 0">
      <div
        style="
          display: flex;
          align-items: center;
          font-weight: bold;
          margin-bottom: 10px;
        "
      >
        每次续费之前会再次确认你的班级变动情况。
      </div>
      <div class="css1">
        <span style="width: 25px; font-size: 14px; margin-right: 5px">省*</span>
        <select @change="getProvinceVal" v-model="userInfoBak.province_id">
          <option
            :key="index"
            v-for="(item, index) in provinceList"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
        <img
          src="../../../assets/select.png"
          style="position: absolute; right: 0; top: 0"
        />
      </div>
      <div class="css1">
        <span style="width: 25px; font-size: 14px; margin-right: 5px">市*</span>
        <select @change="getCityVal" v-model="userInfoBak.city_id">
          <option
            :key="index"
            v-for="(item, index) in cityList"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
        <img
          src="../../../assets/select.png"
          style="position: absolute; right: 0; top: 0"
        />
      </div>
      <div class="css1">
        <span style="width: 25px; font-size: 14px; margin-right: 5px">区*</span>
        <select @change="getRegionVal" v-model="userInfoBak.county_id">
          <option
            :key="index"
            v-for="(item, index) in regionList"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
        <img
          src="../../../assets/select.png"
          style="position: absolute; right: 0; top: 0"
        />
      </div>
      <div
        style="
          display: flex;
          justify-content: space-evenly;
          margin-bottom: 30px;
        "
      >
        <div
          v-for="(item, index) in school"
          :key="index"
          @click="getSchoolList(index)"
          class="item"
          :class="{ active: index === activeCheck ? true : false }"
        >
          <span>{{ item }}</span>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          font-weight: bold;
          margin-bottom: 10px;
        "
      >
        <img src="../../../assets/xuexiao.png" style="margin-right: 5px" />
        学校*
      </div>
      <div style="position: relative; margin-bottom: 20px">
        <select @change="selectFinalSchool" v-model="valObj">
          <option
            v-for="(item, index) in schoolList"
            :value="{ name: item.name, id: item.id }"
            :key="index"
          >
            {{ item.name }}
          </option>
        </select>
        <img
          src="../../../assets/select.png"
          style="position: absolute; right: 0; top: 0"
        />
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          font-weight: bold;
          margin-bottom: 10px;
        "
      >
        <img src="../../../assets/nianji.png" style="margin-right: 5px" />
        年级*
      </div>
      <div style="position: relative; margin-bottom: 20px">
        <select @change="getNowPeriod" v-model="userInfoBak.grade_id">
          <option
            :value="item.value"
            v-for="(item, index) in getPeriod"
            :key="index"
          >
            {{ item.name }}
          </option>
        </select>
        <img
          src="../../../assets/select.png"
          style="position: absolute; right: 0; top: 0"
        />
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          font-weight: bold;
          margin-bottom: 10px;
        "
      >
        <img src="../../../assets/banji.png" style="margin-right: 5px" />
        班级*
      </div>
      <div style="position: relative; margin-bottom: 20px">
        <select @change="chooseClass" v-model="userInfoBak.class_id">
          <option v-for="(item, index) in 40" :value="item" :key="index">
            {{ item }}班
          </option>
        </select>
        <img
          src="../../../assets/select.png"
          style="position: absolute; right: 0; top: 0"
        />
      </div>

      <button type="button" @click="save" style="margin: 20px 0">保存</button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "../../reading/title/header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      activeIndex: -1,
      area: [0, 0, 0],
      valObj: { name: "", id: "" },
      provinceList: [],
      cityList: [],
      regionList: [],
      school: ["小学", "初中", "高中", "大学"],
      activeCheck: -1,
      popVis: false,
      schoolList: [],
      schoolPeriod: "",
      grade: [
        { name: "小一上 (0起点)", value: "X1s" },
        { name: "小一下", value: "X1x" },
        { name: "小二上", value: "X2s" },
        { name: "小二下", value: "X2x" },
        { name: "小三上", value: "X3s" },
        { name: "小三下", value: "X3x" },
        { name: "小四上", value: "X4s" },
        { name: "小四下", value: "X4x" },
        { name: "小五上", value: "X5s" },
        { name: "小五下", value: "X5x" },
        { name: "小六上", value: "X6s" },
        { name: "小六下", value: "X6x" },
        { name: "初一上", value: "C1s" },
        { name: "初一下", value: "C1x" },
        { name: "初二上", value: "C2s" },
        { name: "初二下", value: "C2x" },
        { name: "初三上", value: "C3s" },
        { name: "初三下", value: "C3x" },
        { name: "高一上", value: "G1s" },
        { name: "高一下", value: "G1x" },
        { name: "高二上", value: "G2s" },
        { name: "高二下", value: "G2x" },
        { name: "高三上", value: "G3s" },
        { name: "高三下", value: "G3x" },
        { name: "大一上", value: "B1s" },
        { name: "大一下", value: "B1x" },
        { name: "大二上", value: "B2s" },
        { name: "大二下", value: "B2x" },
        { name: "大三上", value: "B3s" },
        { name: "大三下", value: "B3x" },
        { name: "大四上", value: "B4s" },
        { name: "大四下", value: "B4x" },
        { name: "研一上", value: "Y1s" },
        { name: "研一下", value: "Y1x" },
        { name: "研二上", value: "Y2s" },
        { name: "研二下", value: "Y2x" },
        { name: "研三上", value: "Y3s" },
        { name: "研三下", value: "Y3x" },
        { name: "研四上", value: "Y4s" },
        { name: "研四下", value: "Y4x" },
      ],
      schoolName: "",
      schoolId: 0,
      class_id: 0,
      nowPeriod: "",
      t: 0,

      userInfoBak: {
        province_id: 0,
        city_id: 0,
        county_id: 0,
        grade_id: "",
        class_id: 0,
      },
    };
  },
  methods: {
    changeActiveIndex(index) {
      this.activeIndex = index;
    },
    loseActive() {
      this.activeIndex = -1;
    },
    // 获取省区
    getAreaData(data0, type = 0) {
      let obj = {
        parentid: data0,
        ...this.safeMes,
      };
      this.$http
        .post("/user/city/list", encodeURIComponent(JSON.stringify(obj)))
        .then((res) => {
          let data = res.data;
          if (data.code) return this.$message.error(data.msg);
          if (!type) {
            this.provinceList = data.data;
          } else if (type == 1) {
            this.t++;
            this.cityList = [];
            this.regionList = [];
            this.cityList = data.data;

            //	数组响应式
            this.area.splice(0, 1, data0);
            this.area.splice(1, 1, this.cityList[0].value);
            this.area.splice(2, 1, 0);
          } else {
            this.t++;
            this.regionList = [];
            this.regionList = data.data;
            this.area.splice(1, 1, data0);
            this.area.splice(2, 1, this.regionList[0].value);
          }
        })
        .catch(() => {});
    },
    // 选择省
    getProvinceVal($event) {
      this.getAreaData($event.target.value, 1);
    },
    // 选择市
    getCityVal($event) {
      this.getAreaData($event.target.value, 2);
    },
    // 选择区	得到[2,9,66]的数组
    getRegionVal($event) {
      this.area.splice(2, 1, $event.target.value);
    },
    getSchoolList(index) {
      if (!this.schoolDisabled) {
        this.activeCheck = index;
        let arr = ["X", "C", "G", "D"];
        this.schoolPeriod = arr[index];
        this.toChooseSchool();
      }
    },
    dis() {},
    chooseClass($event) {
      this.class_id = $event.target.value;
    },
    // 获取当前年级
    getNowPeriod($event) {
      this.nowPeriod = $event.target.value;
    },

    // 按钮选择学校
    toChooseSchool() {
      if (this.schoolDisabled || !this.schoolPeriod)
        return this.$message.error("请先选择完地区和学习阶段哦");
      let obj = {
        ...this.safeMes,
        city_id: this.area[2],
        school_type: this.schoolPeriod,
      };
      this.$http
        .post("/user/school/list", JSON.stringify(obj))
        .then((res) => {
          let data = res.data;
          if (data.code)
            return this.$store.commit("judgeCode", {
              code: data.code,
              msg: data.msg,
            });
          this.schoolList = data.data;
          this.schoolName = this.userInfo.school_name;
          this.schoolId = this.userInfo.school_id;
          this.valObj.name = this.schoolName;
          this.valObj.id = this.schoolId;
          this.popVis = true;
        })
        .catch(() => {
          return this.$message.error("获取学校列表失败");
        });
    },
    // 最终选择学校
    selectFinalSchool() {
      this.schoolName = this.valObj.name;
      this.schoolId = this.valObj.id;
      this.popVis = false;
    },

    // 保存
    save() {
      if (
        this.schoolDisabled ||
        !this.schoolPeriod ||
        !this.schoolName ||
        !this.class_id ||
        !this.nowPeriod
      )
        return this.$message.error("请选择完整哦");
      let obj = {
        ...this.safeMes,
        province_id: this.area[0] * 1,
        city_id: this.area[1] * 1,
        county_id: this.area[2] * 1,
        school_id: this.schoolId * 1,
        school_name: this.schoolName,
        school_type: this.schoolPeriod,
        grade_id: this.nowPeriod,
        class_id: this.class_id * 1,
      };
      this.$http
        .post("/user/set/class", JSON.stringify(obj))
        .then((res) => {
          let data = res.data;
          if (data.code) return this.$message.error(data.msg);
          this.$store.commit("destoryPreUserMes");
          if (this.$route.query.type) {
            this.$router.push({ name: "ability", query: { type: 123 } });
          } else
            this.$router.push({
              name: "my",
              query: { pid: getLocalPid() },
            });
          return this.$message.success("设置成功");
        })
        .catch((err) => {
          return this.$message.error(JSON.stringify(err));
        });
    },
  },
  mounted() {
    if (this.userInfo && localStorage.getItem("ways") === "wechat") {
      this.nowPeriod = "X1s";
      this.class_id = 1;
    }
    let title = localStorage.getItem("title");
    if (title) document.title = title;
    console.log(this.userInfo);
    if (this.userInfo) {
      this.userInfoBak.province_id = this.userInfo.province_id;
      this.userInfoBak.city_id = this.userInfo.city_id;
      this.userInfoBak.county_id = this.userInfo.county_id;
      this.userInfoBak.grade_id = this.userInfo.grade_id;
      this.userInfoBak.class_id = this.userInfo.class_id;
    }
    let arr = ["X", "C", "G", "D"];
    if (this.userInfo.hasSchoolInfo) {
      this.t = 2;
      this.nowPeriod = this.userInfo.grade_id;
      this.class_id = this.userInfo.class_id;
      this.activeCheck = arr.indexOf(this.userInfo.school_type);
      this.schoolPeriod = this.userInfo.school_type;
      let info = this.userInfo;
      this.provinceList = info.province_list;
      this.cityList = info.city_list;
      this.regionList = info.county_list;
      this.schoolName = this.userInfo.school_name;
      this.area.splice(0, 1, info.province_id);
      this.area.splice(1, 1, info.city_id);
      this.area.splice(2, 1, info.county_id);
    } else {
      this.getAreaData(1);
    }
    this.toChooseSchool();
  },
  computed: {
    ...mapState({
      safeMes: (state) => state.safeMes,
      userInfo: (state) => state.userInfo,
      title: (state) => state.title,
    }),
    // 查看地区是否选择完整
    schoolDisabled() {
      let index = this.area.findIndex((v) => v == 0);
      // 有没选 禁用
      if (index != -1) return true;
      // 选完了 不禁用
      return false;
    },
    getPeriod() {
      if (this.schoolPeriod == "X")
        return this.grade.filter((v) => {
          return v.value[1] == "B" || v.value[0] == "X";
        });
      if (this.schoolPeriod == "C")
        return this.grade.filter((v) => {
          return v.value[0] == "C";
        });
      if (this.schoolPeriod == "G")
        return this.grade.filter((v) => {
          return v.value[0] == "G";
        });
      if (this.schoolPeriod == "D")
        return this.grade.filter((v) => {
          return v.value[0] == "B" || v.value[0] == "Y";
        });
      return [];
    },
  },
};
</script>

<style scoped>
.css1 {
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
select {
  width: 100%;
  display: block;
  height: 31px;
  padding: 0 10px;
  border: 1px solid #dbdbdb;
  outline: none;
}
.item {
  width: 49px;
  height: 29px;
  border: 0.5px solid #dbdbdb;
  border-radius: 3px;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
}
.active {
  background-image: linear-gradient(-58deg, #2b9af3 6%, #4bb1fa 100%);
  border: 0 none;
  color: #ffffff;
}
button {
  width: 100%;
  height: 55px;
  background-image: linear-gradient(-58deg, #2b9af3 6%, #4bb1fa 100%);
  box-shadow: 0px 8px 14px 0px rgba(74, 144, 226, 0.3);
  border-radius: 100px;
  color: #ffffff;
  box-shadow: none;
  font-size: 17px;
  border: 0 none;
}
</style>
