<template>
  <div class="bg-white" style="height: 100%">
    <Header :title="'登录'"></Header>
    <div style="width: 90%; margin: 20px auto 0">
      <div
        style="
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f4f4f4;
          margin-bottom: 20px;
        "
      >
        <img src="../../../assets/icon_user_login.png" />
        <input
          type="text"
          style="width: 100%"
          v-model="username"
          placeholder="请输入用户名"
        />
        <img
          src="../../../assets/icon_clear.png"
          style="width: 15px"
          v-show="username !== ''"
          @click="username = ''"
        />
        <input type="text" style="position: fixed; bottom: -9999px" />
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f4f4f4;
          margin-bottom: 30px;
        "
      >
        <input type="password" style="position: fixed; bottom: -9999px" />
        <img src="../../../assets/icon_mima_login.png" />
        <input
          :type="isPassWord ? 'password' : 'text'"
          style="width: 100%"
          v-model="password"
          placeholder="请输入密码"
        />
        <img
          src="../../../assets/icon_clear.png"
          style="width: 15px; margin-right: 15px"
          v-show="password !== ''"
          @click="password = ''"
        />
        <img
          v-if="isPassWord"
          src="../../../assets/icon_yincang.png"
          style="width: 15px"
          @click="isPassWord = false"
        />
        <img
          v-else
          src="../../../assets/icon_zhankai.png"
          style="width: 15px"
          @click="isPassWord = true"
        />
      </div>
      <button type="button" @click="login" :disabled="disabled">
        {{ disabled ? "登录中..." : "登录" }}
      </button>
    </div>
  </div>
</template>

<script>
import Header from "../../reading/title/header.vue";
export default {
  components: {
    Header,
  },
  methods: {
    // 登录
    login() {
      let obj = {
        username: this.username,
        password: this.password,
      };
      this.disabled = true;
      this.$http
        .post("/user/login", encodeURIComponent(JSON.stringify(obj)))
        .then((res) => {
          let data = res.data;
          this.disabled = false;
          if (data.code != 0) return this.$message.error(data.msg);
          this.$store.commit("login", data.data);
          localStorage.setItem("ways", "login");
          // 把token保存到本地
          let msg = JSON.stringify(data.data);
          // userMes中有userId和token
          window.localStorage.setItem("userMes", msg);
          this.$router.push({ name: "home", query: { pid: getLocalPid() } });
          localStorage.setItem("ways", "username");
        })
        .catch(() => {
          this.$message.error("发生错误,登录失败");
          this.disabled = false;
        });
    },
  },
  data() {
    return {
      username: "",
      password: "",
      disabled: false,
      isPassWord: true,
    };
  },
};
</script>

<style scoped>
input {
  border: 0 none;
  outline: none;
  background: #ffffff;
  font-size: 16px;
  color: #181818;
  padding: 10px;
}
input::-webkit-input-placeholder {
  color: #b2b2b2;
  font-size: 16px;
}
button {
  width: 100%;
  height: 55px;
  background-image: linear-gradient(-58deg, #2b9af3 6%, #4bb1fa 100%);
  box-shadow: 0px 8px 14px 0px rgba(74, 144, 226, 0.3);
  border-radius: 100px;
  color: #ffffff;
  box-shadow: none;
  font-size: 17px;
  border: 0 none;
}
</style>
