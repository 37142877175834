<template>
  <div class="bg-white" style="height: 100%">
    <Header :title="'阅读计划'"></Header>
    <UserInfo></UserInfo>

    <div
      style="padding: 0 20px; margin-bottom: 20px"
      v-if="
        articleData.specialArticles && articleData.specialArticles.length > 0
      "
    >
      <div
        style="
          overflow: hidden;
          background: #ffffff;
          border: 1px solid #309df4;
          box-shadow: 0px 1px 31px 0px rgba(0, 0, 0, 0.07);
          border-radius: 12px;
          display: flex;
        "
      >
        <div
          style="
            flex: 0 0 35%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: linear-gradient(180deg, #00c6f9 2%, #0077ed 100%);
          "
        >
          <img :src="articleData.specialIcon" style="width: 75%" />
        </div>
        <div style="padding: 20px; flex: 0 0 65%">
          <div
            @click="navTo(item.articleId)"
            v-for="(item, index) in articleData.specialArticles"
            :key="index"
            :style="{
              marginBottom:
                index < articleData.specialArticles.length - 1 ? '15px' : '0',
            }"
          >
            <hr v-if="index>0"/>
            <div>
              <p style="font-size: 16px; color: #4a4a4a; margin-bottom: 7px; margin-top: 7px;">
                {{ item.title }}
              </p>
              <span
                style="
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  color: #4a4a4a;
                "
                >难度：
                <span style="display: flex; align-items: center">
                  <img
                    src="../../../assets/star0.png"
                    v-for="(i, k) in item.levelStar"
                    :key="k + randomString()"
                    style="width: 16px"
                  />
                  <img
                    src="../../../assets/star1.png"
                    v-for="(i, k) in 5 - item.levelStar"
                    :key="k + randomString()"
                    style="width: 16px"
                  />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="padding: 0 20px"
      v-if="
        articleData.recommendArticles &&
        articleData.recommendArticles.length > 0
      "
    >
      <div
        style="
        overflow: hidden;
          background: #ffffff;
          border: 1px solid #309df4;
          box-shadow: 0px 1px 31px 0px rgba(0, 0, 0, 0.07);
          border-radius: 12px;
          display: flex;
        "
      >
        <div
          style="
            flex: 0 0 35%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: linear-gradient(180deg, #00c6f9 2%, #0077ed 100%);
          "
        >
          <img :src="articleData.recommendIcon" style="width: 75%" />
        </div>
        <div style="padding: 20px; flex: 0 0 65%">
          <div
            @click="navTo(item.articleId)"
            v-for="(item, index) in articleData.recommendArticles"
            :key="index"
            :style="{
              marginBottom:
                index < articleData.recommendArticles.length - 1 ? '15px' : '0',
            }"
          >
            <hr v-if="index>0"/>
            <div>
              <p style="font-size: 16px; color: #4a4a4a; margin-bottom: 7px; margin-top: 7px;">
                {{ item.title }}
              </p>
              <span
                style="
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  color: #4a4a4a;
                "
                >难度：
                <span style="display: flex; align-items: center">
                  <img
                    src="../../../assets/star0.png"
                    v-for="(i, k) in item.levelStar"
                    :key="k + randomString()"
                    style="width: 16px"
                  />
                  <img
                    src="../../../assets/star1.png"
                    v-for="(i, k) in 5 - item.levelStar"
                    :key="k + randomString"
                    style="width: 16px"
                  />
                </span>
              </span>
              <p v-if="item.isUncompleted"
                 style="background-color: #ee2323; border-radius: 5px; padding: 1px 5px; width: fit-content"
              >未完成</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 20px"></div>
    <div style="text-align:center">
        <img src="../../../assets/btn_30days_plan.png" style="width:40%;margin:5px;" @click="navToRouter('plan')"/>
        <img src="../../../assets/btn_all_articles.png" style="width:40%;margin:5px;" @click="navToRouter('search')"/>
    </div>
    <div style="height: 130px"></div>
    <tabbar></tabbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import tabbar from "@/components/tabbar/tabbar.vue";
import Header from "../../reading/title/header.vue";
import UserInfo from "../userinfo/userinfo_brief.vue";
export default {
  mounted() {
    let title = localStorage.getItem("title");
    if (title) document.title = title;
    this.$store.commit("saveArticleTitle", "");
    this.$store.commit("saveArticleId", 0);
    if (!this.token) this.getToken();
//    if (this.token && !this.userInfo.nickname) {
    if (this.token) {
      this.$store.dispatch("getUserInfo", "article");
    }
    this.getUrlMes();
  },
  components: {
    tabbar,
    Header,
    UserInfo,
  },
  methods: {
    getToken() {
      this.$store.commit("init");
    },
    navToRouter(to) {
      console.log('navToRouter', to);
      this.$router.push({ name: to, query: { pid: getLocalPid() } });
    },
    navTo(articleId) {
      window.location.href =
        window.location.href.substring(
          0,
          window.location.href.lastIndexOf("/")
        ) +
        "/article?id=" + articleId +
        "&pid=" + getLocalPid();
    },
    randomString: function () {
      const len = 5;
      const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      const maxPos = chars.length;
      let pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    getUrlMes() {
      let url = window.location.href;
      let theRequest = {};
      if (url.indexOf("?") != -1) {
        url = url.split("?")[1];
        let strs = url.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
        if (theRequest.mid) {
          this.$store.commit("saveToArticleId", theRequest.mid);
        }
      } else {
        this.$store.dispatch("getWxAppIdAndJsapi_ticket");
      }
    },
    urlMes() {},
  },
  data() {
    return {
      mid: "",
      pid: getLocalPid(),
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      userId: (state) => state.userId,
      userInfo: (state) => state.userInfo,
      sign: (state) => state.sign,
      safeMes: (state) => state.safeMes,
      articleData: (state) => state.articleData,
      jsApiTicket: (state) => state.jsApiTicket,
    }),
  },
};
</script>

<style scoped>

</style>
