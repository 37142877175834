<template>
  <div style="padding: 20px">
    <div
        style="
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          border-bottom: 1px solid #ddebff;
        "
    >
      <div
          style="
            width: 68px;
            height: 68px;
            border-radius: 50%;
            border: 4px solid #b7d9fd;
            margin-right: 15px;
          "
      >
        <img
            v-if="!userInfo.avatar"
            src="../../../../public/img/author.png"
            style="width: 58px; height: 58px; border-radius: 50%"
        />
        <img
            v-else
            :src="userInfo.avatar"
            style="width: 58px; height: 58px; border-radius: 50%"
        />
      </div>
      <div style="flex: 1">
        <div style="margin-bottom: 5px">ID：{{ userId }}</div>
        <div style="display: flex; align-items: center">
            <span
                style="
                font-size: 23px;
                color: rgb(38, 158, 253);
                display: flex;
                align-items: flex-start;
              "
            >{{ userInfo.level}}<i style="font-size: 12px; font-style: normal; margin: 0 5px">level</i></span>
          <span class="css2">
              <span
                  class="css1"
                  :style="{ width: (userInfo.level / 396) * 100 + '%' }"
              ></span>
            </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  mounted() {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      userId: (state) => state.userId,
      userInfo: (state) => state.userInfo,
      sign: (state) => state.sign,
      safeMes: (state) => state.safeMes,
      articleData: (state) => state.articleData,
      jsApiTicket: (state) => state.jsApiTicket,
    }),
  },
  methods: {
  }
};
</script>

<style scoped>
.css1 {
  background-image: linear-gradient(-58deg, #2b9af3 6%, #4bb1fa 100%);
  display: inline-block;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
}
.css2 {
  width: 43%;
  height: 11.08px;
  background: rgb(151, 209, 255);
  border-radius: 10px;
  display: inline-block;
  position: relative;
  font-size: 14px;
  margin-right: 5px;
}
</style>
