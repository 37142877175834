<!-- 使用场景: 文章页面 - 弹出菜单 - 单词翻译|句子翻译 -->
<template>
  <div v-if="status">
    <div
      v-if="menuVis"
      :style="
        'left:' + left + 'px;top:' + (top + 5) + 'px;width:' + width + 'px;'
      "
      class="flex align-center items css1"
    >
      <div v-for="(item, index) in menu" :key="index" style="width: 48%">
        <div
          class="flex text-white align-center justify-center css11"
          @click="popEvent(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <!-- 遮罩层 -->
    <div @click="allHide" class="css10"></div>
    <!-- 遮罩层 -->

    <!-- 分析 -->
    <div v-if="analyseVis" class="css9">
      <div>
        <div class="flex">
          <div class="css8">
            {{ sentence }}
          </div>
        </div>
        <div id="scroll" ref="scroll" class="css7"></div>
        <analyse
          :articleId="articleId"
          :readLogId="readLogId"
          :type="'component'"
          :bodyStyle="'flex-1;'"
          @toView="toView"
        ></analyse>
      </div>
    </div>
    <!-- 分析 -->

    <!-- 句子翻译 -->
    <div
      v-if="translateVis"
      class="css4"
      :style="
        'left:' + left + 'px;top:' + (top + 5) + 'px;width:' + width + 'px;'
      "
    >
      <div class="flex">
        <div class="css6">
          {{ sentence }}
          <span style="display: flex; align-items: center">
            <img
              @click="toSpeak"
              v-if="audioUrl"
              src="../../../assets/laba.png"
              style="width: 15px; height: 15px"
            />
          </span>
        </div>
      </div>
      <div
        style="height: 1px; background-color: #ececec; margin-top: 3px"
      ></div>
      <div style="margin-top: 5px; margin-bottom: 5px; color: #032b72">
        {{ translate }}
      </div>
    </div>
    <!-- 句子翻译 -->

    <!-- 查单词 -->
    <div
      v-if="wordVis"
      class="css5"
      :style="
        'left:' + left + 'px;top:' + (top + 5) + 'px;width:' + width + 'px;'
      "
    >
      <div class="flex">
        <div style="color: #032b72">
          {{ word }}
        </div>
        <div style="display: flex; align-items: center">
          <img
            @click="toSpeak"
            v-if="audioUrl"
            src="../../../assets/laba.png"
            style="margin-left: 5px; width: 15px; height: 15px"
          />
        </div>
      </div>

      <div
        style="height: 1px; background-color: #ececec; margin-top: 3px"
      ></div>
      <div style="height: 20px" class="flex align-center">
        [<span v-if="translate.phonetic">{{translate.phonetic}}</span>]
      </div>
      <div v-if="translate && translate.translations" style="margin-top: 5px; margin-bottom: 5px">
        <div v-for="(item, index) in translate.translationsV2" :key="index"
             style="color: #032b72; border-bottom-color: #dddddd; border-bottom-style: dashed; border-bottom-width: 0.05rem; padding: 1px;"
        >
          <div style="text-align: left; width: fit-content;">
            <span style="font-size:0.6rem; color: #FFFFFF; background-color: #9f35f9;" v-if="item.isVariant">↓词性要点</span>
            <span style="font-size:0.6rem; color: #FFFFFF; background-color: #f2460a;" v-else-if="item.bestIndexes.length>0">↓最佳解释</span>
          </div>
          <div>
            <span :style="item.bestIndexes.length>0?'color: #f2460a;':''">{{ item.pos }}</span>
            <span v-for="(explain, idxL2) in item.explains" :key="idxL2">
              <template v-if="idxL2>0">,</template>
              <span :style="item.isVariant?'color:#9f35f9;':(item.bestIndexes.indexOf(idxL2) != -1?'color: #f2460a;':'')">{{explain}}</span>
            </span>
          </div>
        </div>
      </div>

      <div v-if="translate && translate.lemma" style="font-style: italic">
        <div style="height: 2px; background-color: #ccc; margin-top: 3px"></div>
        <div style="margin-left: 5px; margin-right: 5px">
          <div style="width: 90%; padding-left: 5px; padding-top: 5px">
            <span>原型:</span><br />
            <span>{{ translate.lemma.word }}</span
            ><br />
            <span v-if="translate.lemma.phonetic" style="color: #485860">{{
              "[ " + translate.lemma.phonetic + " ]"
            }}</span>
          </div>
          <div v-for="(item, index) in translate.lemma.translationsV2" :key="index"
               style="color: #032b72; border-bottom-color: #dddddd; border-bottom-style: dashed; border-bottom-width: 0.05rem; padding: 1px;"
          >
            <div style="text-align: left; width: fit-content;">
              <span style="font-size:0.6rem; color: #FFFFFF; background-color: #9f35f9;" v-if="item.isVariant">↓词性要点</span>
              <span style="font-size:0.6rem; color: #FFFFFF; background-color: #f2460a;" v-else-if="item.bestIndexes.length>0">↓最佳解释</span>
            </div>
            <div>
              <span :style="item.bestIndexes.length>0?'color: #f2460a;':''">{{ item.pos }}</span>
              <span v-for="(explain, idxL2) in item.explains" :key="idxL2">
              <template v-if="idxL2>0">,</template>
              <span :style="item.isVariant?'color:#9f35f9;':(item.bestIndexes.indexOf(idxL2) != -1?'color: #f2460a;':'')">{{explain}}</span>
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 查单词 -->

    <!-- 录音 -->
    <div
      v-if="recordVis"
      class="css2"
      :style="
        'left:' + left + 'px;top:' + (top + 5) + 'px;width:' + width + 'px;'
      "
    >
      <div class="flex">
        <div class="css3">
          {{ sentence }}
        </div>
      </div>

      <div
        style="height: 1px; background-color: #ececec; margin-top: 3px"
      ></div>
      <record
        @secondeHide="secondHide"
        ref="record"
        :stopRecord="stopRecord"
      ></record>
    </div>
    <!-- 录音 -->
  </div>
</template>

<script>
import record from "../record/record.vue";
import analyse from "../analyse/analyse.vue";
import { mapState } from "vuex";
export default {
  components: {
    analyse,
    record,
  },
  props: {
    menu: {
      type: Array,
      default() {
        return [];
      },
    },
    width: {
      type: Number,
      default: 0,
    },
    articleId: {
      type: [Number, String],
      default: 0,
    },
    readLogId: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      pid: getLocalPid(),
      status: false, //不显示
      maxX: 0,
      maxY: 0,
      left: 0,
      top: 0,
      photoVis: false,
      analyseVis: false,
      // 点击位置的x y
      x: 0,
      y: 0,

      photoY: 0,
      translateVis: false,
      wordVis: false,
      translate: "",
      menuVis: true,
      audioUrl: "",
      recordVis: false,
      spk: false,
      ison: false,
      odl: null,
      stopRecord: false,
      // 紧紧翻译
      justTrans: false,
    };
  },
  methods: {
    toView() {
      // document.getElementById('scroll').scrollIntoView()
      // var scr = document.getElementById('scroll')
      // console.log(scr.scrollTop,'scroll')
      setTimeout(() => {
        window.scrollTo(0, this.top + 50);
      }, 100);
    },

    show(x, y) {
      console.log(x, y);
      this.x = x;
      this.y = y;
      // this.maxY = document.body.clientHeight - this.height - 65
      this.maxX = document.body.clientWidth - this.width;
      console.log(this.width);
      console.log(this.maxX);
      this.left = x > this.maxX ? this.maxX / 2 : x;
      // this.top = y > this.maxY ? this.maxY : y
      this.top = y;

      this.status = true;
    },
    allHide() {
      if (
        this.translateVis ||
        this.wordVis ||
        this.analyseVis ||
        this.recordVis
      ) {
        this.secondHide();
      } else {
        this.hide();
      }
    },
    hide() {
      if (this.translateVis) return;
      if (this.wordVis) return;
      if (this.analyseVis) return;
      if (this.recordVis) return;
      this.status = false;
      this.menuVis = true;
      this.ison = false;
      this.$emit("noPopup");
      this.justTrans = false;
      this.$emit("close");
    },
    secondHide() {
      if (this.recordVis) {
        this.$refs.record.stop();
      }
      this.translateVis = false;
      this.analyseVis = false;
      this.wordVis = false;
      this.status = false;
      this.menuVis = true;
      this.recordVis = false;
      this.spk = false;
      this.ison = false;
      if (this.odl) this.odl.pause();
      this.$emit("noPopup");
      this.stopRecord = false;
      this.justTrans = false;
      this.$emit("close");
    },
    // 弹出层事件
    popEvent(item) {
      switch (item.event) {
        case "search":
          this.wordVis = true;
          this.getTranslate();
          break;
        case "teacher":
          if (!this.safeMes.sign) {
            this.$notify({
              title: "提示",
              message: "登录后才能使用这些功能哦",
              type: "warning",
              duration: 1500,
              position: "top-left",
            });
            break;
          }
          this.getTeacherAddress();
          break;
        case "analyse":
          this.menuVis = false;
          this.$nextTick(() => {
            this.analyseVis = true;
          });

          break;
        case "translate":
          this.justTrans = true;
          this.translateVis = true;
          this.getTranslate();
          break;
        case "speak":
          this.translateVis = true;
          this.getTranslate(1);
          break;
        case "record":
          if (!this.safeMes.sign) {
            this.$notify({
              title: "提示",
              message: "登录后才能使用这些功能哦",
              type: "warning",
              duration: 1500,
              position: "top-left",
            });
            break;
          }
          this.menuVis = false;
          this.recordVis = true;

          break;
        default:
          this.hide();
          this.$emit("menuItemClick", item);
          break;
      }
    },

    // 翻译
    getTranslate(type = 0) {
      this.audioUrl = "";
      this.translate = null;
      this.menuVis = false;
      if (type) {
        this.spk = true;
        return this.getSpeaker();
      }
      let obj = {
        ...this.safeMes,
        articleId: this.articleId - 0,
        readLogId: this.readLogId - 0,
        content: this.translateVis ? this.sentence : this.word,
        // setence是查句子
        sentence: this.translateVis ? "" : this.sentence,
        offset: this.translateVis ? 0 : this.offset - 0,
      };

      this.$http
        .post("/biz/translate", encodeURIComponent(JSON.stringify(obj)))
        .then(({ data }) => {
          if (data.code)
            return this.$store.commit("judgeCode", {
              code: data.code,
              msg: data.msg,
            });
          data = data.data;

          this.translate = data.sentenceResult
            ? data.sentenceResult.translate
            : data.wordResult;
          console.log("this.translate", this.translate);
          if (!this.justTrans) this.getSpeaker();
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err);
        });
    },

    //AI朗读
    getSpeaker() {
      try {
        this.menuVis = false;
        let obj = {
          ...this.safeMes,
          articleId: this.articleId - 0,
          readLogId: this.readLogId - 0,
          content: this.translateVis ? this.sentence : this.word,
        };
        this.$http
          .post("/biz/tts", encodeURIComponent(JSON.stringify(obj)))
          .then(({ data }) => {
            if (data.code)
              return this.$store.commit("judgeCode", {
                code: data.code,
                msg: data.msg,
              });
            this.audioUrl = data.data.audioUrl;
          })
          .catch((err) => {
            this.$message.error(err);
          });
      } catch (err) {
        this.$message.error(JSON.stringify(err) + "1");
      }
    },
    toSpeak() {
      if (!this.ison) {
        try {
          this.odl = new Audio();
          this.odl.src = this.audioUrl;
          this.odl.play();
          this.ison = true;
          this.odl.addEventListener("ended", () => {
            this.ison = false;
          });
        } catch (err) {
          this.$message.error(JSON.stringify(err));
        }
      }
    },
    getTeacherAddress() {
      let obj = {
        ...this.safeMes,
        pid: getLocalPid(),
      };
      this.$http
        .post("/biz/url/service", encodeURIComponent(JSON.stringify(obj)))
        .then(({ data }) => {
          if (data.code === 10015) return this.$router.push("/teacher");
          if (data.code) return this.$message.error(data.msg);

          if (data) {
            window.location.href = data.data;
          }
        })
        .catch((err) => {
          return this.$message.error(err + " 获取名师地址失败 ");
        });
    },
  },
  computed: {
    ...mapState(["articleData", "sentence", "word", "offset", "safeMes"]),
  },
  watch: {
    audioUrl(newValue) {
      if (newValue == "") return;
      if (this.spk) this.toSpeak();
    },
  },
};
</script>

<style scoped>
.items > div > div {
  margin-bottom: 12px;
}
.items > div:nth-child(8) > div,
.items > div:nth-child(7) > div {
  margin-bottom: 0;
}
.items {
  position: relative;
}
.css1 {
  z-index: 5;
  position: absolute;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 5px;
  box-sizing: content-box;
  padding: 20px;
  justify-content: space-between;
  box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}
.css2 {
  z-index: 5;
  position: absolute;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 5px;
  box-sizing: content-box;
  padding: 20px;
  justify-content: space-between;
  box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.5);
  width: 280px;
}
.css3 {
  width: 100%;
  padding-left: 5px;
  padding-top: 5px;
  word-break: break-all;
  color: #032b72;
}
.css4 {
  z-index: 5;
  position: absolute;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 5px;
  box-sizing: content-box;
  padding: 20px;
  justify-content: space-between;
  box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.5);
  width: 280px;
}
.css5 {
  z-index: 5;
  position: absolute;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 5px;
  box-sizing: content-box;
  padding: 20px;
  justify-content: space-between;
  box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.5);
  width: 280px;
  box-sizing: border-box;
}
.css6 {
  width: 100%;
  color: #032b72;
  word-break: break-all;
  display: flex;
  align-items: center;
}
.css7 {
  height: 1px;
  background-color: #ececec;
  margin-top: 3px;
  margin-bottom: 6px;
}
.css8 {
  color: #000;
  width: 100%;
  padding-left: 5px;
  padding-top: 5px;
  word-break: break-all;
  color: #032b72;
}
.css9 {
  z-index: 5;
  background-color: #ffffff;
  position: fixed;
  padding: 20px;
  border-radius: 5px;
  flex-wrap: wrap;
  border-radius: 5px;
  box-sizing: content-box;
  justify-content: space-between;
  box-shadow: 0 2px 23px 0 rgb(0 0 0 / 50%);
  box-sizing: border-box;
  width: 90%;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  max-height: 80%;
  height: auto;
  overflow-y: scroll;
}
.css10 {
  background-color: rgba(231, 231, 231, 0.2);
  z-index: 2;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.css11 {
  width: 100%;
  height: 36px;
  background: #ddebff;
  border: 1px solid #0086f0;
  border-radius: 100px;
  color: #0086f0;
}
</style>
