<template>
  <div>
    <Header :title="'30日阅读计划'"></Header>
    <UserInfo></UserInfo>

    <!-- BOF: 文章列表 -->
    <div
        style="padding: 0 20px"
        v-if="
        articleData.recommendArticles &&
        articleData.recommendArticles.length > 0
      "
    >
      <div style="overflow: hidden; background: #ffffff; border: 1px solid #309df4;
        box-shadow: 0px 1px 31px 0px rgba(0, 0, 0, 0.07); border-radius: 12px;">

        <div style="padding: 20px;">
          <div
              @click="navTo(item.articleId)"
              v-for="(item, index) in articleData.recommendArticles"
              :key="index"
              :style="{marginBottom: index < articleData.recommendArticles.length - 1 ? '15px' : '0',}"
          >
            <hr v-if="index>0"/>
            <div>
              <p style="font-size: 16px; color: #4a4a4a; margin-bottom: 7px; margin-top: 7px;">
                {{ item.title }}
              </p>
              <span style="display: flex; align-items: center; font-size: 14px; color: #4a4a4a;">难度：
                <span style="display: flex; align-items: center">
                  <img src="../../../assets/star0.png" v-for="(i, k) in item.levelStar" :key="k + randomString()" style="width: 16px"/>
                  <img src="../../../assets/star1.png" v-for="(i, k) in 5 - item.levelStar" :key="k + randomString" style="width: 16px"/>
                </span>
              </span>
              <p v-if="item.isUncompleted" style="background-color: #ee2323; border-radius: 5px; padding: 1px 5px; width: fit-content">
                未完成
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- EOF: 文章列表 -->

    <div style="height: 130px"></div>
    <tabbar></tabbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "../title/header.vue";
import tabbar from "../../tabbar/tabbar.vue";
import UserInfo from "../userinfo/userinfo_brief.vue";
import $http from "axios";
import {Message} from "element-ui";
export default {
  components: {
    Header,
    tabbar,
    UserInfo
  },
  mounted() {
    this.$nextTick(function () {
      this.request30DaysArticles();
    });
  },
  data() {
    return {
      articleData:{}
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      userId: (state) => state.userId,
      userInfo: (state) => state.userInfo,
      sign: (state) => state.sign,
      safeMes: (state) => state.safeMes,
//      articleData: (state) => state.articleData,
      jsApiTicket: (state) => state.jsApiTicket,
    }),
  },
  methods: {
    request30DaysArticles() {
      let obj = {
        token: this.token,
        sign: this.sign,
        userId: this.userId,
        pid: getLocalPid(),
      }
      let thiz = this;
      $http.post('/article/list-plan', JSON.stringify(obj))
          .then(res => {
            thiz.articleData = res.data.data;
          })
          .catch(() => {
            Message.error('获取文章列表失败 请稍后再试')
          });
    },
    randomString() {
      const len = 5;
      const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      const maxPos = chars.length;
      let pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    navTo(articleId) {
      window.location.href =
          window.location.href.substring(
              0,
              window.location.href.lastIndexOf("/")
          ) +
          "/article?id=" + articleId +
          "&pid=" + getLocalPid();
    },
  }
};
</script>
