<template>
  <div
    id=""
    class="position-fiexd"
    style="left: 0; top: 0; right: 0; z-index: 12"
    :style="maxW ? 'max-width:' + maxW + 'px;' : ''"
  >
    <div
      id=""
      class="position-relative"
      style="
        height: 45px;
        box-sizing: border-box;
        padding: 2px 3px 0 3px;
        z-index: 12;
      "
    >
      <!-- 标题 -->
      <div
        id=""
        style="background-color: #3982cc"
        class="h-100 w-100 flex align-center justify-center text-white"
      >
        <em style="font-size: 12px; margin-top: 5px; margin-left: 5px">
          {{ userInfo.id ? "ID:" + userInfo.id : "" }}
        </em>
        <span class="font-bold" style="margin: 0 5px; font-size: 16px">
          {{ title }}
        </span>
        <em style="font-size: 12px; margin-top: 3px">
          {{ userInfo.level ? "Level:" + userInfo.level : "" }}
        </em>
      </div>
      <!-- 标题 -->

      <!-- 左边图片 -->
      <div
        v-if="timer"
        id=" "
        style="line-height: 45px"
        class="
          top-0
          left-0
          right-0
          bottom-0
          text-left
          position-absolute
          flex
          align-center
        "
      >
        <img
          class=""
          src="../../../../public/img/reading/article/alarm_clock.png"
          style="width: 25px; height: 25px; margin-left: 10px"
        />
        <div
          style="margin-left: 3px; margin-top: 6px"
          class="text-white font-bold"
        >
          {{ timeFomat }}
        </div>
      </div>
      <!-- 左边图片 -->

      <!-- 右边图片 -->
      <div
        id=" "
        style="line-height: 45px"
        class="top-0 left-0 right-0 bottom-0 text-right position-absolute"
      >
        <img
          @click="doCopy()"
          class=""
          src="../../../../public/img/reading/copy_url.png"
          style="
            width: 25px;
            height: 25px;
            margin-top: 10px;
            margin-right: 10px;
          "
        />
      </div>
      <!-- 右边图片 -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    timer: {
      type: Number,
      default: 0,
    },
    articleId: {
      type: [String, Number],
      default: "",
    },
  },
  mounted() {
    this.maxW = document.body.clientWidth;
    if (!this.title) this.getTitle();
    var a = setInterval(() => {
      if (this.timer > 0) {
        this.time = this.timer;
        // console.log(this.time,'总时长是')
        this.timerMinus();
        clearInterval(a);
      }
    }, 500);
  },
  data() {
    return {
      maxW: 100000,
      time: 0,
      pid: getLocalPid(),
    };
  },
  methods: {
    timerMinus() {
      setInterval(() => {
        this.time--;
      }, 1000);
    },
    getTitle() {
      // 请求title
      setTimeout(() => {
        this.$http
          .get("/partner/get/sitename?pid=" + getLocalPid())
          .then((res) => {
            let data = res.data;
            if (data.code != 0) return this.$message.error(data.msg);
            this.$store.commit("getTitle", data.data.siteName);
            window.localStorage.setItem("title", data.data.siteName);
            document.title = data.data.siteName;
          })
          .catch((err) => {
            console.log(err);
            this.$message.error("标题请求失败");
          });
      }, 500);
    },
    doCopy() {
      if (!this.pid) return this.$message.error("请先获取pid");
      let index = window.location.href.lastIndexOf("/");
      let url = window.location.href.substring(0, index);
      url += "/index?pid=" + this.pid;
      if (this.articleId) url = window.location.href;
      console.log(url);

      this.$copyText(url).then(
        (e) => {
          this.$message.success("复制地址成功");
          console.log(e);
        },
        (e) => {
          this.$message.success("复制失败");
          console.log(e);
        }
      );
    },
  },
  computed: {
    timeFomat() {
      if (this.time < 0) return "0:00";
      if (this.time % 60 < 10)
        return Math.floor(this.time / 60) + " : 0" + (this.time % 60);
      return Math.floor(this.time / 60) + ":" + (this.time % 60);
    },
    ...mapState({
      title: (state) => state.title,
      userInfo: (state) => state.userInfo,
    }),
  },
};
</script>

<style>
</style>
