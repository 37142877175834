<template>
  <div id="nav" class="position-fiexd tabbar">
    <div @click="navTo('index')" class="item">
      <img src="../../assets/Icon_booking.png" style="margin-top: 30px" />
      <span class="font-bold">原版阅读</span>
    </div>
    <div @click="navTo('photo')" class="item">
      <img src="../../assets/iconCenter.png" style="margin-bottom: 5px" />
      <span class="font-bold">AI工具</span>
    </div>
    <div @click="navTo('my')" class="item">
      <img src="../../assets/iconuser.png" style="margin-top: 30px" />
      <span class="font-bold">个人中心</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 跳转
    navTo(to) {
      if('photo' === to) {
        if(location.pathname === '/photo') {
          location.replace(location.href);
          return;
        }
      }
      this.$router.push({ name: to, query: { pid: getLocalPid() } });
    },
  },
  mounted() {
    this.maxW = document.body.clientWidth;
  },
  data() {
    return {
      maxW: 2000,
      pid: getLocalPid(),
    };
  },
};
</script>

<style scoped>
.tabbar {
  display: flex;
  width: 100%;
  bottom: 0;
  height: 100px;
  justify-content: space-between;
  background-image: url("../../assets/bar.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: center;
}
.item {
  display: flex;
  flex-flow: column;
  width: 30%;
  align-items: center;
}
</style>
