import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.use(ElementUI);
Vue.prototype.$http = axios

axios.defaults.baseURL = "//readapi.bluebirdabc.com"
//axios.defaults.baseURL = "http://127.0.0.1:8181"
axios.interceptors.request.use(config => {
	// config.headers.Authorization = store.state.token
	config.headers['T-Init-Span'] = getInitSpanMillis();
	return config
})
axios.interceptors.response.use(response => {
	console.log(response)
	if (response.data.code === 1005) {
		store.commit("exit");
		router.push({ name: "home", query: { pid: getLocalPid() } });
	}
	return response
}, error => {
	if (error && error.response) {
		console.log(error)
		return Promise.reject(error)
	}
})

Vue.config.productionTip = false
new Vue({
	store,
	router,
	render: h => h(App)
}).$mount('#app')
