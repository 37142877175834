<template>
  <div class="bg-white" style="height: 100%">
    <Header :title="'续费充值'"></Header>
    <div style="width: 90%; margin: 40px auto 0">
      <div
        style="
          font-size: 20px;
          color: #181818;
          margin-bottom: 20px;
          text-align: center;
        "
      >
        请输入12位卡密进行充值
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f4f4f4;
          margin-bottom: 20px;
        "
      >
        <input type="password" style="position: fixed; bottom: -9999px" />
        <img src="../../../assets/icon_mima_login.png" />
        <input
          :type="isPassWord ? 'password' : 'text'"
          style="width: 100%"
          v-model="card"
          placeholder="请输入密码"
        />
        <img
          src="../../../assets/icon_clear.png"
          style="width: 15px; margin-right: 15px"
          v-show="card !== ''"
          @click="card = ''"
        />
        <img
          v-if="isPassWord"
          src="../../../assets/icon_yincang.png"
          style="width: 15px"
          @click="isPassWord = false"
        />
        <img
          v-else
          src="../../../assets/icon_zhankai.png"
          style="width: 15px"
          @click="isPassWord = true"
        />
      </div>
      <div style="display: flex; justify-content: space-between">
        <button @click="clearCard()" class="btn">清空</button>
        <button
          @click="recharge()"
          class="btn"
          style="
            background-image: linear-gradient(-58deg, #2b9af3 6%, #4bb1fa 100%);
            border: 0 none;
            color: #ffffff;
          "
        >
          提交
        </button>
      </div>
      <button
        type="button"
        @click="navToBuy"
        v-if="address"
        style="margin-top: 30px"
      >
        去学习商城购买卡密
      </button>
      <!-- <div id="" style="width: 100%; margin-top: 20px" class="text-center">
        <button type="button" class="hover" @click="clearCard()">清空</button>
        <button
          type="button"
          style="margin-left: 5px; background-color: #34d88b; color: white"
          class="hover"
          @click="recharge()"
        >
          提交
        </button>
      </div>
      <div id="" style="margin-top: 80px" class="text-center">
        <button
          type="button"
          class="btn"
          style="background-color: #66afe9; color: white; font-weight: 700"
          @click="navToBuy()"
          v-if="address"
        >
          去学习商城购买卡密
        </button>
      </div> -->
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import tabbar from "../../tabbar/tabbar.vue";
import Header from "../../reading/title/header.vue";
export default {
  components: {
    Header,
    tabbar,
  },
  data() {
    return {
      isPassWord: true,
      card: "",
      // 商城地址
      address: "",
      pid: getLocalPid(),
    };
  },
  mounted() {
    this.getStoreAddress();
  },
  methods: {
    // 充值
    recharge() {
      let obj = {
        ...this.safeMes,
        cardNumber: this.card,
        pid: getLocalPid(),
      };
      console.log(obj);
      this.$http
        .post("/biz/recharge", encodeURIComponent(JSON.stringify(obj)))
        .then((res) => {
          let data = res.data;
          console.log(data);
          if (data.code)
            return this.$store.commit("judgeCode", {
              code: data.code,
              msg: data.msg,
            });
          if (data.data.cardType == "READING")
            this.$message.success("阅读服务 充值成功");
          else this.$message.success("名师在线服务 充值成功");
          this.$store.commit("destoryPreUserMes");
          this.clearCard();
        })
        .catch((err) => {
          console.log(err);
          return this.$message.error("充值失败 请稍后再试");
        });
    },

    //
    clearCard() {
      this.card = "";
    },

    //去商城
    navToBuy() {
      window.location.href = this.address;
    },

    // 获取商城地址
    getStoreAddress() {
      let obj = {
        ...this.safeMes,
        pid: getLocalPid(),
      };
      console.log(obj);
      this.$http
        .post("/biz/url/store", encodeURIComponent(JSON.stringify(obj)))
        .then((res) => {
          let data = res.data;
          console.log(data);
          if (data.code) return this.$message.error(data.msg);
          this.address = data.data;
          // this.$store.commit('destoryPreUserMes')
        })
        .catch((err) => {
          console.log(err);
          return this.$message.error("发生错误 获取商城地址失败");
        });
    },
  },
  computed: {
    ...mapState({
      safeMes: (state) => state.safeMes,
    }),
  },
};
</script>

<style scoped>
input {
  border: 0 none;
  outline: none;
  background: #ffffff;
  font-size: 16px;
  color: #181818;
  padding: 10px;
}
input::-webkit-input-placeholder {
  color: #b2b2b2;
  font-size: 16px;
}
button {
  width: 100%;
  height: 55px;
  background-image: linear-gradient(-58deg, #2b9af3 6%, #4bb1fa 100%);
  box-shadow: 0px 8px 14px 0px rgba(74, 144, 226, 0.3);
  border-radius: 100px;
  color: #ffffff;
  box-shadow: none;
  font-size: 17px;
  border: 0 none;
}
.btn {
  width: 48%;
  color: #666666;
  font-size: 14px;
  background: none;
  height: 29px;
  border: 0.5px solid #dbdbdb;
  border-radius: 3px;
}
</style>
